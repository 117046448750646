import React from "react";
import { Link, useNavigate } from "react-router-dom";
import NavbarComp from "../../components/Navbar/navbar";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../redux/Auth/auth";
import BottomTabComp from "../../components/BottomTab/bottomTab";

const Account = () => {

  const dispatch = useDispatch()
  const {isAuthenticated} = useSelector((state) => state.auth);

  const navigate = useNavigate()
  const handleLogout = () =>{
    dispatch(Logout())
    navigate('/')
  }

  return (
    <>
    <div className="px-4 md:px-10 py-6 bg-[#f5f5f5] min-h-screen relative">
        <NavbarComp />
      <h1 className="font-bold text-2xl mt-8">Account</h1>

      {
        isAuthenticated
        ?
        <div className="mt-6">
        <Link to="/wallet" className="hover:cursor-pointer">
          <h2 className="font-semibold text-base">Wallet</h2>
          <p className="text-xs mb-4 text-secondary1">
            Monitor payments and transaction
          </p>
          <hr />
        </Link>
        <Link to="/profile" className="hover:cursor-pointer">
          <h2 className="font-semibold text-base mt-4">Profile</h2>
          <p className="text-xs mb-4 text-secondary1">
            Manage your Crop2Cash profile
          </p>
          <hr />
        </Link>
        <Link to="/orders" className="hover:cursor-pointer">
          <h2 className="font-semibold text-base mt-4">Orders</h2>
          <p className="text-xs mb-4 text-secondary1">
            Manage and track your orders
          </p>
          <hr />
        </Link>
        <div
          onClick={handleLogout}
        className="hover:cursor-pointer hidden md:block">
          <h2 className="font-semibold text-base mt-4">Logout</h2>
          <p className="text-xs mb-4 text-secondary1">
            Logout of your account
          </p>
          <hr />
        </div>

        <div className="mt-20 md:hidden block ">
          <button 
           onClick={handleLogout} 
           className="border border-[#B92043] w-full rounded-lg py-4 px-8 text-[#B92043] font-medium text-sm">
            Log Out
          </button>
       </div>
      </div>
      :
      <div className="mt-6">
      <Link to="/signup" className="hover:cursor-pointer">
        <h2 className="font-semibold text-base">Sign Up</h2>
        <p className="text-xs mb-4 text-secondary1">
          Sign up with us.
        </p>
        <hr />
      </Link>
      <Link to="/login" className="hover:cursor-pointer">
        <h2 className="font-semibold text-base mt-4">Login</h2>
        <p className="text-xs mb-4 text-secondary1">
          Login to your account
        </p>
        <hr />
      </Link>
    </div>
      }


      {/* logout */}
      {/* <div className="md:hidden block absolute bottom-44 left-0 right-0 px-4 ">
          <button 
           onClick={handleLogout} 
           className="border border-[#B92043] w-full rounded-lg py-4 px-8 text-[#B92043] font-medium text-sm">
            Log Out
          </button>
      </div> */}
       
    </div>
      {/* footer */}
      <BottomTabComp />
    </>
  );
};

export default Account;
