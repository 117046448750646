import React,{useEffect, useState} from 'react'
import back from "../../assets/back.svg";
import { useNavigate,Link } from "react-router-dom";
import airtime from "../../assets/airtime.svg";
import transfer_icon from "../../assets/transfer_icon.svg";
// import transfer_debit from "../../assets/transfer_debit.svg";
import order_icon from "../../assets/shopping_bag_active.svg";
import content_copy from "../../assets/content_copy.svg";
import account_wallet_icon from "../../assets/account_balance_wallet_active.svg";
// import account_balance_wallet_active from "../../assets/account_balance_wallet_active.svg";
import moment from "moment";
import Modal from '../../components/Modals/modal'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import cogoToast from 'cogo-toast';
import NavbarComp from '../../components/Navbar/navbar';
import { useSelector } from 'react-redux';
import BottomTabComp from '../../components/BottomTab/bottomTab';
import { useDispatch } from 'react-redux';
import { getWalletTransactions } from '../../redux/Wallet/walletActions';
import PaginationComponent from '../../components/Pagination/Pagination';
import { Form, Formik } from 'formik'
import { accountValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import eye_off from '../../assets/eye-off.svg'
import wallet_eye_off from '../../assets/wallet_eye_off.svg'
import wallet_eye_on from '../../assets/wallet_eye_on.svg'
import eye from '../../assets/eye.svg'
import { createVirtualAcct, getFarmersProfile, getVirtualAcct } from '../../redux/Auth/authActions';
import { clearAcctCreated } from '../../redux/Auth/auth';
// +2347063113708 1234

const Wallet = () => {

  const [show, setShow] = useState(false)
  const [showAcctModal, setShowAcctModal] = useState(false)
  const { account_number, bank_name, isAcctProcessing, createAcctLoader, acctCreated, balance} = useSelector((state) => state.auth);
  const {transactions, count, loading} = useSelector((state) => state.wallet);
  const dispatch = useDispatch()

  const [passwordShown, setPasswordShown] = useState(false)
  const [currencyShown, setCurrencyShown] = useState(true)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
}

const toggleCurrencyVisiblity = () => {
  setCurrencyShown(currencyShown ? false : true)
}


  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const getMoreItemsByOffset = (page) => {
    setCurrentPage(page)
    dispatch(getWalletTransactions({
      page: page
    }))
  }

  const formatIcon = (val, type) =>{
    let res;
    switch(val){
      case 'mechanization_order':
        res = order_icon
       break;
      case 'transfer':
        res = transfer_icon
        break;
      case 'airtime':
        res = airtime
        break;
      default:
        res = order_icon 
      break;
    }
    return res;
  }


  const showModal = () =>{
    setShow(!show)
  }

  const accountModal = () =>{
    setShowAcctModal(!showAcctModal)
}  

  const handleCopy = () =>{
    cogoToast.success('Account number has been copied')
  }


  const navigate = useNavigate();
  const backFunc = () => {
    navigate("/account");
  };

  const formatBalance = (val) =>{
    let res;
    res = val/100
    return res.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }


  const handleSubmit = (values) => {
    dispatch(createVirtualAcct({
      bvn: values.bvn,
      pin: values.pin
    }))
  }

  useEffect(()=>{
    if(acctCreated) {
      setTimeout(()=>{
        dispatch(getVirtualAcct())
        setShowAcctModal(false) 
        dispatch(clearAcctCreated())
      },2000)
    }
  },[dispatch, acctCreated])


  useEffect(()=>{
    if(account_number !== null){
      dispatch(getWalletTransactions({
        page:  1
      }))
      dispatch(getFarmersProfile())
    }
  },[dispatch, account_number])

  useEffect(()=>{
    dispatch(getVirtualAcct())
  },[dispatch])

  return (
    <>
       {/* checkout confirmation modal */}
       <Modal show={show} showModal={showModal} 
       title="Receive Money"
       subtitle="Transfers to this bank account are credited to your crop2cash wallet."
       >
            {/* products display and address */}
            <div className='mt-6'>
              <div className='bg-[#EDF7EE] rounded-lg py-8 px-3'>
                <p className='text-[#2B8C34] text-sm font-medium text-center'>{bank_name ? bank_name : "N/A"}</p>
                <h4 className='text-[#2B8C34] text-center text-lg md:text-xl font-semibold'>{account_number ? account_number : "N/A"}</h4>
              </div>

                {/* items */}
                <p className='text-[#7C8D7E] font-medium text-sm text-center mt-4'>
                Transfers to this bank account are credited to your crop2cash wallet.
                </p>

                <div className='mt-8'>
                  <CopyToClipboard 
                   text={account_number? account_number : '0100000000'}
                   onCopy={() => handleCopy()}
                    className='bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg w-full
                    flex justify-center
                    cursor-pointer
                     text-white py-4 px-6 text-sm'>
                      <div className='flex items-center'>
                      <img src={content_copy} alt="copy" className='mr-2' />
                        <span>Copy Account Number</span>
                      </div>
                      </CopyToClipboard>
                </div>
            </div>
     </Modal>

     {/* Request for account number */}
     <Modal show={showAcctModal} showModal={accountModal} 
       title="Request Account"
       subtitle="We are mandated by law and compliance to collect your Bank Verification Number."
       >
            {/* products display and address */}
            <div className='mt-6'>
            <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={accountValidator}
            initialValues={{
              bvn: '',
              pin: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-5" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor='bvn' className="block mb-2 text-secondary text-sm font-medium">
                   Enter your BVN
                  </label>
                  <input
                    type="text"
                    name="bvn"
                    placeholder="Enter your BVN number"
                    className={
                      touched.bvn && errors.bvn
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.bvn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.bvn && errors.bvn ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.bvn && errors.bvn}
                      </small>
                    </div>
                  ) : null}
                </div>
     
                <div className="mt-5">
                  <label htmlFor='pin' className="block mb-2 text-secondary text-sm font-medium">
                    Pin
                  </label>
                  <div className="relative">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="pin"
                      placeholder="Enter your 4 digit pin"
                      className={
                        touched.pin && errors.pin
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.pin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.pin && errors.pin ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.pin && errors.pin}
                      </small>
                    </div>
                  ) : null}
                </div>
          
                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={createAcctLoader}
                    className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Request
                  </button>
                </div>

             
              </Form>
            )}
          </Formik>
            </div>
     </Modal>

    <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
       <NavbarComp />

      <div className="flex text-center mt-8">
        <span
          className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-secondary1 hover:cursor-pointer"
          onClick={() => backFunc()}
        >
          <img src={back} alt="back" />
        </span>
        <h6 className="mx-auto font-semibold text-base md:text-base">Wallet</h6>
      </div>

      {
        account_number === null &&  !isAcctProcessing &&
       <div className="md:max-w-md mx-auto max-w-xs md:mt-44 mt-32">

            <div className='flex justify-center'>
                <div className='flex justify-center items-center bg-[#EDF7EE] w-[64px] h-[64px] rounded-full'>
                <img src={account_wallet_icon} alt="account" className='w-[25px] h-[25px]' />
                </div>
            </div>

            <div className='mt-4 text-center'>
              <p className='text-[#344335] text-sm font-semibold'>Request Account Number</p>
            </div>
            <p className="text-[#96A397] font-semibold text-sm text-center mt-2 ">
            Request an account number to able to carry out cash transactions 
            </p>
            <div className="mt-4 text-center">
              <button
              onClick={()=> setShowAcctModal(true)}
                className='
                py-4 px-6 bg-primary rounded-[8px] text-white 
                text-sm font-medium hover:bg-[#24752B] transition-all'
              >
                Request Account Number
              </button>
            </div>
        </div>
        }

      {isAcctProcessing && account_number === null &&
       <div className="max-w-md mx-auto md:mt-44 mt-32">
            <div className='flex justify-center'>
                <div className='flex justify-center items-center bg-[#EDF7EE] w-[64px] h-[64px] rounded-full'>
                <img src={account_wallet_icon} alt="account" className='w-[25px] h-[25px]' />
                </div>
            </div>
          
          <div className='mt-4 text-center'>
              <p className='text-[#344335] text-sm font-semibold'>Your account is being processed</p>
            </div>
            <p className="text-[#96A397] font-semibold text-sm text-center mt-2 leading-6">
            Our team is currently processing your account. We appreciate your patience as we work to verify your information and set up your account.
            </p>

            <div className='mt-28 text-center'>
                 <p className='text-[#344335] text-sm font-medium'>Have questions or need assistance?</p>
                  <a href="tel:09030009891" className='text-[#2B8C34] text-sm font-medium'>Contact Customer Support</a>
             </div>
        </div>
      }


     { account_number !== null && isAcctProcessing &&
     <>
      <div className="my-6 max-w-sm">
        <div className="bg-tertiary text-white bg-wallet-pattern bg-no-repeat bg-right px-4 rounded-lg">
          <div className='flex justify-between items-center'>
            <div className="pt-10 pb-11">
              <p className="font-medium text-white ">Wallet Balance</p>
              {
                currencyShown ?
                <h2 className="text-3xl font-semibold text-white mt-1">₦{balance ? formatBalance(balance): 0.00}</h2>
                :
                <h2 className="text-3xl font-semibold text-white mt-1">₦****</h2>
              }
             
            </div>
            <div className='bg-white w-[32px] h-[32px] rounded-full flex justify-center items-center'>
              <img 
                  onClick={toggleCurrencyVisiblity}
                  src={currencyShown ? wallet_eye_on : wallet_eye_off}
               alt="wallet off" className='cursor-pointer' />
           </div>
          </div>
        </div>
        <div className="flex my-6 gap-4">
          <button 
          onClick={()=>setShow(true)}
          className="border-primary border w-full py-3 rounded-lg text-primary">
            Receive Money
          </button>
          <Link to="/send-money" className="border-primary 
          flex justify-center
          border w-full py-3 rounded-lg text-primary">
            Send Money
          </Link>
        </div>
      </div>
      <div>
        <div className="flex justify-between items-center">
          <h3 className="text-secondary font-semibold">Transaction History</h3>
          {/* <p className="text-sm text-primary font-medium">View All</p> */}
        </div>

        <div>
          {transactions.map(({ action, amount, transaction_type, created }) => {
            return (
              <div className="flex items-center gap-3 my-5">
                <span className="bg-tertiary1 p-3 rounded w-[40px] h-[40px] flex justify-center items-center">
                  <img src={formatIcon(action, transaction_type)} alt={action} />
                </span>
                <div className="w-full">
                  <div className="flex justify-between  font-semibold">
                  <p className="capitalize text-[#344335] ">
                      {action.includes("order") ? "order" : action}
                    </p>
                  <p className="text-[#344335]">
                      {transaction_type === "debit" ? "-": "+"}₦{amount}
                    </p>
                  </div>
                  <div className="self-end">
                    <p className="text-[#5C715E] text-xs font-medium">{moment(created).format('Do, MMM YYYY')}</p>
                  </div>
                </div>
              </div>
            );
          })}

          {transactions.length === 0 &&  
            <div className='mt-16 text-center'>
                <p className='text-[#5C715E] font-medium text-base'>You haven't made any transaction(s) yet</p>
            </div>
            }
        </div>

          {/* pagination */}
          {
             loading ?
                ""
                :
              transactions.length > 0 && transactions ?
                <div className="flex justify-center mt-10 mb-10">
                     <PaginationComponent
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={PageSize}
                    onPageChange={(page) => getMoreItemsByOffset(page)}
                  />
            </div>
            :
            ""
            } 

      </div>
      </>
      }


    </div>

       {/* footer */}
       <BottomTabComp />
    </>
  );
};

export default Wallet;
