import { createSlice } from '@reduxjs/toolkit'
import { getOrders } from './orderActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'


const initialState = {
    loader: false,
    orders: [],
    ordersCount: 0
}

const orderPersistConfig = {
  key: 'order',
  storage,
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    increment: (state) => {
        state.count += 1
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
      },
    
  },
  extraReducers: {
    //   Get Orders ----------
    [getOrders.pending]: (state)=>{
        state.loader = true
    },
    [getOrders.fulfilled]: (state, {payload}) =>{
        state.loader = false
        state.orders = payload.data
        state.ordersCount = payload.total
    },
    [getOrders.rejected]: (state) =>{
        state.loader = false
    }
   
  }
})

const orderReducer = persistReducer(orderPersistConfig, orderSlice.reducer);


// Action creators are generated for each case reducer function
export const { increment } = orderSlice.actions

export default orderReducer