import React from 'react'
import internetIcon from '../../assets/signal_cellular_nodata.svg'

const NoInternetPage = () => {
    return (  
        <>
            <div className='flex flex-col justify-center items-center min-h-screen'>

                <div className='w-[64px] h-[64px] flex justify-center items-center bg-[#EDF7EE] rounded-full'>
                     <img src={internetIcon} alt="internetIcon" />
                </div>

                <div className='mt-4'>
                    <p className='text-[#344335] text-sm font-semibold'>No Connection</p>
                </div>
                <div className='mt-1'>
                    <p className='text-[#96A397] max-w-xs md:max-w-full text-center leading-5 text-sm font-semibold'>Please check your internet connection and try again</p>
                </div>


                <div className='mt-4'>
                <button
                    type="submit"
                    className="bg-primary 
                    disabled:bg-[#ABB6AC] text-sm
                    rounded-md py-3 px-6 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Try Again
                  </button>
                </div>

            </div>
        </>
    );
}
 
export default NoInternetPage;