import React,{useState, useEffect} from 'react'
import logo from '../../assets/crop2cash.svg'
import { Link, useNavigate } from 'react-router-dom'
import eye_off from '../../assets/eye-off.svg'
import eye from '../../assets/eye.svg'
import { Form, Formik } from 'formik'
import { onboardThirdValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import back from "../../assets/back.svg";
import { useDispatch, useSelector } from 'react-redux'
import { userRegister } from '../../redux/Auth/authActions'
import { clearSignUpSuccess } from '../../redux/Auth/auth'

const OnboardThirdPage = () => {

    const [passwordShown, setPasswordShown] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {signUpLoader,verifycreds,signup_success} = useSelector((state) => state.auth);


    const backFunc = () => {
      navigate("/onboard/location");
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const handleSubmit = (values) => {
        let resp = {
          ...verifycreds,
          pin: values.pin
        }
        dispatch(userRegister(resp))
    }

    useEffect(()=>{
      if(signup_success){
        setTimeout(() =>{
          dispatch(clearSignUpSuccess())
          navigate('/login')
        },[3000])
      }
    },[signup_success, navigate, dispatch])


    return ( 
        <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#FBEB96] w-[450px] h-[450px] rounded-full blur-[200px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* signup section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-16 py-10">

         <div className='flex justify-between items-center'>
                <div>
                    <div 
                       onClick={() => backFunc()}
                        className="bg-[#ECEEEC] w-[24px] h-[24px] rounded-full flex items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer">
                        <img src={back} alt="back" />
                    </div>
                </div>
                <div>
                    <p className='text-[#344335] text-sm md:text-base font-semibold'>Sign Up</p>
                </div>
                <div>
                    <p className='text-[#344335] text-sm font-semibold'>3/3</p>
                </div>
            </div>

            {/* progress bar */}
            <div className='flex gap-1 mt-5'>
                <div className='bg-[#2B8C34] rounded-[2px] w-full h-[4px]'></div>
                <div className='bg-[#2B8C34] rounded-[2px] w-full'></div>
                <div className='bg-[#2B8C34] rounded-[2px] w-full'></div>
            </div>

          <div className='mt-4'>
            <h6 className="text-[#344335] font-semibold text-xl">
            Create your 4 digit pin
            </h6>
            <p className="text-[#5C715E] font-medium text-sm mt-1">
            Create your 4 digit pin to secure your account
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={onboardThirdValidator}
            initialValues={{
              pin: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-5" onSubmit={handleSubmit}>

                <div className="">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Pin
                  </label>
                  <div className="relative">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="pin"
                      placeholder="Enter a 4 digit pin"
                      className={
                        touched.password && errors.password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.pin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.pin && errors.pin ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.pin && errors.pin}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={signUpLoader}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                   Sign Up
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Already have an account? <Link to="/login" className='text-primary font-semibold text-sm'>Log In</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        </>
     );
}
 
export default OnboardThirdPage;