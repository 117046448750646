import React,{useEffect, useState} from 'react'
import back from "../../assets/back.svg";
import { useNavigate } from "react-router-dom";
import warning from '../../assets/warning.svg'
import info_icon from '../../assets/info.svg'
import user_icon from '../../assets/user.svg'
import warning_amber from '../../assets/warning_amber.svg'
import { useFormik } from 'formik'
import { sendMoneyValidator } from '../../validationSchema/validator'
import Select from 'react-select';
// import {banks} from '../../helpers/banks'
import Modal from '../../components/Modals/modal'
import PinInput from "react-pin-input";
import NavbarComp from '../../components/Navbar/navbar';
import { useSelector, useDispatch } from 'react-redux';
import { getBanks, getOTP, processTransfer, verifyAccountNumber, verifyOTP } from '../../redux/Wallet/walletActions';
import cogoToast from 'cogo-toast';
import { clearTransferSent, clearValidity } from '../../redux/Wallet/wallet';
import BottomTabComp from '../../components/BottomTab/bottomTab';

const SendMoneyPage = () => {

    const navigate = useNavigate();
    const [isBankError, setIsBankError] = useState(false)
    const [selectedBank, setSelectedBank] = useState("")

    const [sendActive] = useState(true)


    const [pin, setPin] = useState("");
    const [otp, setOtp] = useState("");
    const [valid] = useState(false);
    const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const [showCodeModal, setShowCodeModal] = useState(false)
    const [oneTimePinPayment, setOneTimePinPayment] = useState(false)
    const [pinPayment, setPinPayment] = useState(false)
    const [maxPayment, setMaxPayment] = useState(false)
    const [transferInfo, setTransferInfo] = useState({})

    const {userInfo} = useSelector((state) => state.auth);
    const {accountName, validAccount, invalidAccount, isTranferSent, isValidOtp,verifyLoader, banks, transferLoader} = useSelector((state) => state.wallet);

    const formatBalance = (val) =>{
        let res;
        res = val/100
        return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      } = useFormik({
        initialValues: {
            accountNumber: '',
            amount: '',
            narration: ''
        },
        validationSchema: sendMoneyValidator,
        onSubmit(values, { setSubmitting }) {
        handSubmit(values, setSubmitting);
        },
      });

    const handlePin = (val) => {
        setPin(val);
      };

    const handleOtp = (val) =>{
     setOtp(val)
    }

    const showModal = () =>{
        setShow(!show)
    }

    const codeModal = () =>{
        setShowCodeModal(!showCodeModal)
    }  

    const backFunc = () => {
        navigate("/wallet");
      };

      const handleBank = (val) =>{
        setIsBankError(false)
        setSelectedBank(val.id)

        // verify account number
        let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
        if(re.test(values.accountNumber)){
            if(values.accountNumber.length === 10){
                dispatch(verifyAccountNumber({
                    bank_id: parseInt(val.id),
                    accountNumber: values.accountNumber,  
                }))
            }
        }
      }

      const handleVerifyAcct = (accountNumber) =>{
        let re = /^-?[0-9]+(.[0-9]{1-7})?$/;
        if(re.test(accountNumber)){
            if(accountNumber.length === 10 && selectedBank !== ""){
                dispatch(verifyAccountNumber({
                    bank_id: parseInt(selectedBank),
                    accountNumber: accountNumber,  
                }))
            }
        }
      }

      const handleAmount = (amount) =>{
        let amt = parseFloat(amount)
        let re = /^[0-9]*\.?[0-9]{0,2}$/;
            if(re.test(amount)){
                if(amt <= 5000 ){
                    // show pin modal
                    setOneTimePinPayment(false)
                    setMaxPayment(false)
                    setPinPayment(true)
                }
                if(amt > 5000 && amt <= 20000){
                    // show otp moal
                    setOneTimePinPayment(true)
                    setMaxPayment(false)
                    setPinPayment(false)
                }
                if(amt > 20000){
                    // show error for max value
                    cogoToast.info('You can only make tranfers up to ₦20,000.00 per day')
                    setMaxPayment(true)
                    setOneTimePinPayment(false)  
                    setPinPayment(false)
                }
            }
            else{
                cogoToast.error('Please enter a valid amount')
            }

      }

      const verifyOtp = () =>{
        // dispatch to verify OTP
         dispatch(verifyOTP({
            otp: otp
         }))
      }


    const handSubmit = (values) => {
        if(selectedBank === ""){
            setIsBankError(true)
        }
        else{
            // check what value was entered to know which modal to show
            if(pinPayment){    

                setTimeout(()=>{
                    setShowCodeModal(true)
                },100)
                setTransferInfo(
                    {
                        ...values
                    }
                ) 
            }
            if(oneTimePinPayment){

                setTimeout(()=>{
                    setShow(true)
                },100)
                dispatch(getOTP())
                setTransferInfo(
                    {
                        ...values
                    }
                )
            }
            if(maxPayment){
                cogoToast.info('You can only make tranfers up to ₦20,000.00 per day')
            }        
        }  
    }

    const sendPayment = () =>{
      let resp = {
        ...transferInfo,
        pin:pin,
        bank: selectedBank
      }
      dispatch(processTransfer({
        bank_id: parseInt(resp.bank),
        amount: parseFloat(resp.amount),
        account_number: resp.accountNumber,
        account_name:accountName,
        pin:resp.pin,
        narration: resp.narration
      }))
    }

    useEffect(()=>{
        if(isValidOtp){
            setShow(false)
            setTimeout(()=>{
                setShowCodeModal(true)
                dispatch(clearValidity())
            },2000) 
        }
      },[isValidOtp, dispatch])

    useEffect(()=>{
        if(isTranferSent){
         setTimeout(()=>{
            dispatch(clearTransferSent())
            setShowCodeModal(false)
            navigate('/wallet')
         },800)   
        }
    },[isTranferSent, dispatch, navigate])

    useEffect(()=>{
       dispatch(getBanks()) 
       dispatch(clearTransferSent())
    },[dispatch])
    

    return ( 
        <>  
          {/* confirm payment */}
        <Modal show={showCodeModal} showModal={codeModal} 
        title="Confirm Payment"
        subtitle="Enter your 4 digit pin to confirm transfer"
        >
                {/* products display and address */}
                <div className='mt-10'>
                {/* pin layout */}
                <div className='text-center'>
                    <PinInput
                        length={4}
                        initialValue={pin}
                        focus
                        secret
                        type="numeric"
                        onChange={handlePin}
                        inputStyle={{ borderColor: "#96A397" }}
                        inputFocusStyle={{ borderColor: "#2B8C34" }}
                    />
                        <small className="text-[#B92043] font-medium text-xs" >
                        {valid ? "Incorrect Pin!": ""}
                        </small>
                    </div>

                
                    <div className='mt-12'>
                    <button 
                    disabled={transferLoader}
                    onClick={sendPayment}
                        className='bg-primary 
                        disabled:bg-[#ABB6AC] 
                        rounded-lg w-full text-white py-4 px-6 text-sm'>Send</button>
                    </div>
                </div>
        </Modal>

        {/* otp confirmation modal */}
        <Modal show={show} showModal={showModal} 
            title="Security Verification"
            subtitle="Hey, we sent you a 4 digit code to verify its you "
         >
                {/* products display and address */}
                <div className='mt-10'>
                {/* pin layout */}
                <div className='text-center'>
                    <PinInput
                        length={4}
                        initialValue={otp}
                        focus
                        type="numeric"
                        onChange={handleOtp}
                        inputStyle={{ borderColor: "#96A397" }}
                        inputFocusStyle={{ borderColor: "#2B8C34" }}
                    />
                        <small className="text-[#B92043] font-medium text-xs" >
                        {valid ? "Wrong verification number": ""}
                        </small>
                    </div>

                
                    <div className='mt-12'>
                    <button 
                      disabled={verifyLoader}
                      onClick={verifyOtp}
                        className='bg-primary 
                        disabled:bg-[#ABB6AC] 
                        rounded-lg w-full text-white py-4 px-6 text-sm'>Verify</button>
                    </div>
                </div>
        </Modal>

            <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
                <NavbarComp />
                <div className="flex text-center mt-8">
                    <span
                    className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-secondary1 hover:cursor-pointer"
                    onClick={() => backFunc()}
                    >
                    <img src={back} alt="back" />
                    </span>
                    <h6 className="mx-auto font-semibold text-base md:text-base">Send Money</h6>
                </div>

               {sendActive &&
                <div className="md:max-w-lg lg:max-w-md mx-auto md:py-10 py-10">
                    <div className='bg-[#EDF7EE] rounded-lg py-8 px-3'>
                        <p className='text-[#2B8C34] text-sm font-medium text-center'>Wallet Balance</p>
                        <h4 className='text-[#2B8C34] text-center text-2xl md:text-2xl font-semibold'>₦{userInfo && userInfo.balance ? formatBalance(userInfo.balance): 0.00}</h4>
                    </div>

                    <form className="mt-8" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor='accountNumber' className="block mb-2 text-secondary text-sm font-medium">
                            Account Number
                            </label>
                            <input
                            type="text"
                            name="accountNumber"
                            placeholder="Account Number"
                            className={
                                touched.accountNumber && errors.accountNumber
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.accountNumber}
                            onChange={(e) => {
                                handleChange(e);
                                handleVerifyAcct(e.currentTarget.value);
                              }}
                            onBlur={handleBlur}
                            />
                            {touched.accountNumber && errors.accountNumber && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.accountNumber && errors.accountNumber}
                                </small>
                            </div>
                            ) }
                          
                        </div>

                     <div className="mt-5">
                        <label className="block mb-2 text-secondary text-sm font-medium">
                            Bank
                        </label>
                        <Select 
                            onChange={handleBank}
                            classNamePrefix="select_control"
                            className="select_style"
                            options={banks}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            />
                        </div>
                        {isBankError ? (
                            <div className="flex">
                            <img src={warning} className="" alt="warning" />
                            <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                                Bank is required
                            </small>
                            </div>
                        ) : null}

                        {
                          invalidAccount && 
                                <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                Account number is invalid
                                </small>
                            </div>
                            }

                            {validAccount &&
                                <div className="flex mt-2 items-center">
                                <img src={user_icon} className="" alt="user" />
                                <small className="text-[#2B8C34] font-medium text-xs pl-[5.65px]">
                                    {accountName}
                                </small>
                            </div>
                            }

                        <div className='mt-5'>
                            <label htmlFor='amount' className="block mb-2 text-secondary text-sm font-medium">
                            Amount
                            </label>
                            <input
                            type="number"
                            name="amount"
                            placeholder="₦0.00"
                            className={
                                touched.amount && errors.amount
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.amount}
                            onChange={(e) => {
                                handleChange(e);
                                handleAmount(e.currentTarget.value);
                              }}
                            onBlur={handleBlur}
                            />
                            {touched.amount && errors.amount ? (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.amount && errors.amount}
                                </small>
                            </div>
                            ) : 
                            <div className="flex mt-2 items-center">
                                <img src={info_icon} className="" alt="info" />
                                <small className="text-[#0066AF] font-medium text-xs pl-[5.65px]">
                                    You can only make tranfers up to ₦20,000.00 per day 
                                </small>
                            </div>
                            }
                           
                        </div>

                        <div className='mt-5'>
                            <label htmlFor='narration' className="block mb-2 text-secondary text-sm font-medium">
                            Narration (optional)
                            </label>
                            <input
                            type="text"
                            name="narration"
                            placeholder="Enter Narration"
                            className={
                                touched.narration && errors.narration
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.narration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {touched.narration && errors.narration ? (
                            <div className="flex">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                                {touched.narration && errors.narration}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className="mt-6">
                            <button
                            type="submit"
                            className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                            >
                            Continue
                            </button>
                        </div>
                        </form>
                 
                </div>
                }

               {!sendActive && 
               <div className='md:max-w-lg lg:max-w-md mx-auto md:py-10 py-10'>
                    <div className='flex justify-center mt-10'>
                        <div className='bg-[#FEF0EC] w-[64px] h-[64px] rounded-full flex justify-center
                        items-center'>
                            <img src={warning_amber} alt="warning_amber" />
                        </div>
                    </div>

                    <div className='mt-6 text-center'>
                        <p className='text-[#344335] font-semibold
                        text-base'>Please Note!</p>
                    </div>

                    <div className='mt-4 text-center'>
                        <p className='text-sm text-[#96A397] font-semibold leading-5'>
                        The 'Send Money' feature is temporarily unavailable. Please use the USSD code *347*46*2# on your mobile number for transactions. We apologize for any inconvenience and thank you for your patience as we resolve the web version issue.
                        </p>
                    </div>

                    <div className='mt-20 text-center'>
                        <p className='text-[#344335] font-medium text-sm'>Have questions or need assistance?</p>
                        <a href='tel:09030009891' className='text-[#2B8C34] font-medium text-sm mt-1'>Contact Customer Support</a>
                    </div>


                </div>
                }



            </div>

         {/* footer */}
          <BottomTabComp />
        </>
     );
}
 
export default SendMoneyPage;