import React, { useEffect } from "react";
// import productImg from "../../assets/product.webp";
// import product1 from "../../assets/product1.png";
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../../redux/Product/productActions";
import input_img from '../../assets/input_img.svg'


function ProductCard({products}) {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector((state) => state.product)


  const handleProduct = (id)=>{
    navigate(`/product/${id}`)
  }

  useEffect(()=>{
    dispatch(getAllProducts())
  },[dispatch])

  return (
    <>
       {loading &&
          ['2', '1', '4', '5', '3', '6', '9', '8'].map(() => (
          <div className="animate-pulse w-full">
                <div className="bg-gray-200 md:h-[200px] h-[150px]  rounded-lg  w-full"></div>     
          </div>
        ))}

       {!loading && products.length
          ? products.map((item) => (
      <div key={item.id}>
        {
          item.images !== null && item.images.length > 0 
          ?
          <div className="max-w-full max-h-full">
          <img
          onClick={()=> handleProduct(item.id)} accept="image/*,image/webp"
          className="rounded-md w-full object-contain md:h-[200px] h-[150px] cursor-pointer" src={item.images[0]} alt="product" />
          </div>
          :
          <div
          onClick={()=> handleProduct(item.id)}
           className='bg-[#E6E6E6] rounded-md w-full md:h-[200px] h-[150px] flex justify-center items-center cursor-pointer'>
            <img 
            src={input_img}  accept="image/*,image/webp" alt="item" className='rounded-lg w-[40px] h-[40px]' />
          </div>
        }
      
        <h6 className="text-base text-[#344335] font-bold mt-2">{item.name} ({item.product_size}{item.measurement})</h6>
        <p className="text-[14px] text-[#344335]">{item.organization_name}</p>
        <p className="text-[14px] font-medium text-[#7C8D7E]">{item.product_category ? item.product_category.name: ''}</p>
        <p className="font-semibold text-[14px] text-[#344335]">₦{item.display_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
      </div>
       ))
    :
     ""
    }
  </>
  );
}

export default ProductCard;
