import React,{useEffect} from 'react'
import logo from '../../assets/crop2cash.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { registerValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import { useDispatch, useSelector } from 'react-redux'
// import { verifyPhoneNumber } from '../../redux/Auth/authActions'
import { clearOTpSent, setRegCreds } from '../../redux/Auth/auth'
import { requestOTP } from '../../redux/Auth/authActions'

const SignUpPage = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {loading,isOTPSent} = useSelector((state) => state.auth);

    const handleSubmit = (values) => {
        dispatch(requestOTP({
          phone_number: '+234' + values.phoneNumber.substring(1)
        }))

        let resp = {
          phone_number: values.phoneNumber,
          step: 0
        }
        dispatch(setRegCreds(resp))
    }

    useEffect(()=>{
      if(isOTPSent){
        navigate('/verify-otp')
      }
      setTimeout(()=>{
        dispatch(clearOTpSent())
      },2000)
    },[isOTPSent, navigate, dispatch])


    return ( 
        <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#FBEB96] w-[450px] h-[450px] rounded-full blur-[200px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* signup section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-10">
          <div>
            <h6 className="text-[#344335] font-semibold text-xl">
              Create Account
            </h6>
            <p className="text-[#5C715E] font-medium text-sm mt-1">
               Welcome to marketplace, create a profile to explore our products and services.
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={registerValidator}
            initialValues={{
              phoneNumber: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-5" onSubmit={handleSubmit}>

              <div className="">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className={
                      touched.phoneNumber && errors.phoneNumber
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-10">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                   Continue
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Already have an account? <Link to="/login" className='text-primary font-semibold text-sm'>Log In</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        </>
     );
}
 
export default SignUpPage;