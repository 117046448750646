import React,{useEffect, useState} from 'react'
import NavbarComp from '../../components/Navbar/navbar';
import { useNavigate } from 'react-router-dom';
import back from "../../assets/back.svg";
import { useFormik } from 'formik'
import { profileValidator } from '../../validationSchema/validator'
// import Select from 'react-select';
import warning from '../../assets/warning.svg'
// import {banks} from '../../helpers/banks'
import BottomTabComp from '../../components/BottomTab/bottomTab';
import { useDispatch, useSelector } from 'react-redux';
import { getFarmersProfile } from '../../redux/Auth/authActions';
import moment from 'moment';
import eye_off from '../../assets/eye-off.svg'
import eye from '../../assets/eye.svg'

const ProfilePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { isAuthenticated, farmerInfo } = useSelector((state) => state.auth)

    const [passwordShown, setPasswordShown] = useState(false)
    const [DOBShown, setDOBShown] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const toggleDOBVisiblity = () => {
        setDOBShown(DOBShown ? false : true)
    }


    const backFunc = () => {
        navigate("/account");
      };

      const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      } = useFormik({
        initialValues: {
            firstName: farmerInfo ? farmerInfo.first_name: '',
            lastName: farmerInfo ? farmerInfo.last_name: '',
            phoneNumber: farmerInfo ? farmerInfo.phone_number: '',
            dateOfBirth :  farmerInfo && farmerInfo.date_of_birth ? moment(farmerInfo.date_of_birth).format('DD-MM-YYYY') : 'DOB',
            gender :  farmerInfo && farmerInfo.gender ? farmerInfo.gender === 'f' ? 'Female' : 'Male' : 'Others',
            bvn: farmerInfo ? farmerInfo.bvn : ''
        },
        validationSchema: profileValidator,
        enableReinitialize: true,
        onSubmit(values, { setSubmitting }) {
        handSubmit(values, setSubmitting);
        },
      });

      const handSubmit = () => {
      }

      useEffect(()=>{
        if(isAuthenticated){
            dispatch(getFarmersProfile())
        }
      },[isAuthenticated,dispatch])

    return ( 
        <>
            <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
                <NavbarComp />
                <div className="flex text-center mt-8">
                    <span
                    className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-secondary1 hover:cursor-pointer"
                    onClick={() => backFunc()}
                    >
                    <img src={back} alt="back" />
                    </span>
                    <h6 className="mx-auto font-semibold text-base md:text-base">Profile</h6>
                </div>

                <div className="md:max-w-lg lg:max-w-md mx-auto md:py-10 py-10">

                    <div className='bg-[#EDF7EE] rounded-lg py-8 px-6 flex gap-4 items-center'>
                       <div className='bg-[#2B8C34] w-[48px] h-[48px] rounded-full flex justify-center items-center'>
                            <p className='text-white font-medium text-base'>{farmerInfo && farmerInfo.last_name ? farmerInfo.last_name.charAt(0).toUpperCase() : 'A'}{farmerInfo && farmerInfo.first_name ? farmerInfo.first_name.charAt(0).toUpperCase() : 'K'}</p>
                       </div>
                       <div>
                            <h6 className='text-[#344335] font-medium text-base'>{farmerInfo ? farmerInfo.last_name : 'N/A'} {farmerInfo? farmerInfo.first_name: 'N/A'}</h6>
                                <p className='text-[#344335] text-sm font-medium'>{farmerInfo? farmerInfo.phone_number: 'N/A'}</p>
                       </div>
                    </div>

                    <form className="mt-8" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor='firstName' className="block mb-2 text-secondary text-sm font-medium">
                            First Name
                            </label>
                            <input
                            type="text"
                            name="firstName"
                            disabled
                            placeholder="First Name"
                            className={
                                touched.firstName && errors.firstName
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {touched.firstName && errors.firstName && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.firstName && errors.firstName}
                                </small>
                            </div>
                            ) }
                          
                        </div>

                        <div className='mt-5'>
                            <label htmlFor='lastName' className="block mb-2 text-secondary text-sm font-medium">
                            Last Name
                            </label>
                            <input
                            type="text"
                            disabled
                            name="lastName"
                            placeholder="Last Name"
                            className={
                                touched.lastName && errors.lastName
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {touched.lastName && errors.lastName && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.lastName && errors.lastName}
                                </small>
                            </div>
                            ) }
                          
                        </div>

                        <div className='mt-5'>
                            <label htmlFor='phoneNumber' className="block mb-2 text-secondary text-sm font-medium">
                            Phone Number
                            </label>
                            <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            disabled
                            className={
                                touched.phoneNumber && errors.phoneNumber
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {touched.phoneNumber && errors.phoneNumber && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.phoneNumber && errors.phoneNumber}
                                </small>
                            </div>
                            ) }
                          
                        </div>



                        <div className='mt-5'>
                            <label htmlFor='dateOfBirth' className="block mb-2 text-secondary text-sm font-medium">
                            Date Of Birth 
                            </label>
                            <div className='relative'>
                            <input
                            type="text"
                            name="dateOfBirth"
                            placeholder="DD-MM-YYYY"
                            disabled
                            className={
                                touched.dateOfBirth && errors.dateOfBirth
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={DOBShown ? values.dateOfBirth : 'XXXXXXX'  }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                             <img
                              onClick={toggleDOBVisiblity}
                                src={DOBShown ? eye_off : eye}
                                alt="eye"
                                className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                          
                            {touched.dateOfBirth && errors.dateOfBirth && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.dateOfBirth && errors.dateOfBirth}
                                </small>
                            </div>
                            ) }
                          
                        </div>

                        <div className='mt-5'>
                            <label htmlFor='gender' className="block mb-2 text-secondary text-sm font-medium">
                            Gender 
                            </label>
                            <div className='relative'>
                                <input
                                type="text"
                                name="gender"
                                placeholder="Gender"
                                disabled
                                className={
                                    touched.gender && errors.gender
                                    ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                    : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                                }
                                value={values.gender}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </div>
                            {touched.gender && errors.gender && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.gender && errors.gender}
                                </small>
                            </div>
                            ) }
                          
                        </div>

                  

                        <div className='mt-5'>
                            <label htmlFor='bvn' className="block mb-2 text-secondary text-sm font-medium">
                            BVN 
                            </label>
                            <div className='relative'>
                              <input
                            type={passwordShown ? 'text' : 'password'}
                            name="bvn"
                            placeholder={passwordShown ? 'Enter your BVN' : 'XXXXXXXX'}
                            disabled
                            className={
                                touched.bvn && errors.bvn
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                            }
                            value={values.bvn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                              onClick={togglePasswordVisiblity}
                                src={passwordShown ? eye_off : eye}
                                alt="eye"
                                className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                                />
                            </div>
                         
                            {touched.bvn && errors.bvn && (
                            <div className="flex items-center mt-2">
                                <img src={warning} className="" alt="warning" />
                                <small className="text-[#B92043] font-medium text-xs pl-[5.65px]">
                                {touched.bvn && errors.bvn}
                                </small>
                            </div>
                            ) }
                          
                        </div>
                       
                
                        <div className="mt-6">
                            <button
                            type="submit"
                            disabled
                            className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                            >
                            Save
                            </button>
                        </div>
                        </form>
                 
                </div>
            </div>

               {/* footer */}
              <BottomTabComp />
        </>
     );
}
 
export default ProfilePage;