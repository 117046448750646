import React, { useState } from "react";
import NavbarComp from "../../components/Navbar/navbar";
import tracker_search from '../../assets/tracker_search.svg'
import { ExpandMore } from "@mui/icons-material";
import store from '../../assets/tracker.jpeg'
import illustration from '../../assets/Frame 9031.svg'
import thumb from '../../assets/thumb_up.svg'
import share from '../../assets/share.svg'
import AddPost from "../../components/AddPost";
import DrawerComponent from "../../components/Drawer/drawer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TrackerPage = () => {
  const [show, setShow] = useState(false)
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate()
  return (
    <>
      <div className="relative">
        <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
          <NavbarComp />

          {isAuthenticated ? <div>
            {/* HEADER */}
            <div className="mt-8 flex justify-between gap-6 items-center mb-6">
              <p className="text-2xl font-bold leading-[30px] text-[#344335] w-[210px] md:w-full  cursor-pointer">Commodity Price Tracker</p>
              <img src={tracker_search} alt="tracker search" className="" />
            </div>
            {/* FILTER */}
            <div className="flex flex-wrap gap-[10px] mb-[19px]">
              <div className="px-2 h-[38px] cursor-pointer rounded-[8px] border-[0.5px] border-[#96A397] bg-[#FFFFFF] flex justify-between gap-2 items-center">
                <p>Commodity</p>
                <ExpandMore className="" style={{ fontSize: "20px" }} />
              </div>
              <div className="px-2 h-[38px] cursor-pointer rounded-[8px] border-[0.5px] border-[#96A397] bg-[#FFFFFF] flex justify-between gap-2 items-center">
                <p>State</p>
                <ExpandMore className="" style={{ fontSize: "20px" }} />
              </div>
              <div className="px-2 h-[38px] cursor-pointer rounded-[8px] border-[0.5px] border-[#96A397] bg-[#FFFFFF] flex justify-between gap-2 items-center">
                <p>LGA</p>
                <ExpandMore className="" style={{ fontSize: "20px" }} />
              </div>
            </div>
          </div> : <div>
            <div className="mt-8 flex flex-col justify-center items-center">
              <img src={illustration} alt="illustration" className="" />
              <p className="text-2xl font-normal leading-[30px] text-[#344335]">Welcome to</p>
              <p className="text-2xl font-bold leading-[30px] text-[#344335] mb-[2px]">Commodity Price Tracker</p>
              <p className="text-sm font-normal leading-[18px] text-[#344335] mb-[16px]">View commodity price updates as at a glance!</p>

            </div>
            <div className='flex gap-[38px]'>
              <button
                type="submit"
                onClick={() => navigate(`/login`)}
                className="border-primary border-[1px] text-primary bg-white
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-3  font-medium transition-all"
              >
                Log In
              </button>
              <button
                type="submit"
                onClick={() => navigate(`/signup`)}
                className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-3  text-white font-medium hover:bg-[#24752B] transition-all"
              >
                Sign Up
              </button>
            </div>
          </div>}


          {/* CARDS */}
          <div>
            <div className="mb-5 w-full" >
              <div className="mt-[20px] p-4 tracker mb-[8px]">
                <div className="flex items-center">
                  <div className="flex gap-[8px] items-center">
                    <div className="rounded-[4px] w-[48px] h-[48px]">
                      <img src={store} alt="product" className="object-cover rounded-[4px] w-[48px] h-[48px]" />

                    </div>
                    <p className="text-2xl font-semibold leading-6 text-primary underline underline-offset-3 cursor-pointer" onClick={isAuthenticated ? null : () => setShow(true)}>Rice</p>
                  </div>


                </div>
                <div className="mt-4">
                  <p className="text-sm font-semibold leading-6 text-[#344335] mb-2">Agric Centre, University of Ibadan, Oyo State.</p>
                  <p className="text-primary text-2xl font-bold leading-6 mb-4">₦100,000/<span className="text-sm">Bag</span></p>
                  <div className="flex text-[#5C715E] font-semibold leading-5 justify-between gap-6 text-sm mb-4">
                    <p>01/01/2023 </p>
                    <p>By Adekunle Kunle</p>
                  </div>
                </div>
              </div>
              <div className="flex text-[#5C715E] text-sm font-medium leading-5">
                <div className="w-1/2 tracker_like px-2 py-[10px] flex  justify-center items-center gap-2 cursor-pointer">
                  <img src={thumb} alt="thumb" className="" onClick={isAuthenticated ? null : () => setShow(true)} />

                  <p>120</p>
                </div>
                <div className="w-1/2 tracker_share px-2 py-[10px] flex justify-center items-center gap-2 cursor-pointer" onClick={isAuthenticated ? null : () => setShow(true)} >
                  <img src={share} alt="share" className="" />

                  <p>120</p>
                </div>
              </div>
            </div>

            <div className="mb-5 w-full" >
              <div className="mt-[20px] p-4 tracker mb-[8px]">
                <div className="flex items-center">
                  <div className="flex gap-[8px] items-center">
                    <div className="rounded-[4px] w-[48px] h-[48px]">
                      <img src={store} alt="product" className="object-cover rounded-[4px] w-[48px] h-[48px]" />

                    </div>
                    <p className="text-2xl font-semibold leading-6 text-primary underline underline-offset-3 cursor-pointer">Rice</p>
                  </div>


                </div>
                <div className="mt-4">
                  <p className="text-sm font-semibold leading-6 text-[#344335] mb-2">Agric Centre, University of Ibadan, Oyo State.</p>
                  <p className="text-primary text-2xl font-bold leading-6 mb-4">₦100,000/<span className="text-sm">Bag</span></p>
                  <div className="flex text-[#5C715E] font-semibold leading-5 justify-between gap-6 text-sm mb-4">
                    <p>01/01/2023 </p>
                    <p>By Adekunle Kunle</p>
                  </div>
                </div>
              </div>
              <div className="flex text-[#5C715E] text-sm font-medium leading-5">
                <div className="w-1/2 tracker_like px-2 py-[10px] flex  justify-center items-center gap-2 cursor-pointer">
                  <img src={thumb} alt="thumb" className="" onClick={() => setShow(true)} />

                  <p>120</p>
                </div>
                <div className="w-1/2 tracker_share px-2 py-[10px] flex justify-center items-center gap-2 cursor-pointer">
                  <img src={share} alt="share" className="" onClick={() => setShow(true)} />

                  <p>120</p>
                </div>
              </div>
            </div>

            <div className="mb-5 w-full" >
              <div className="mt-[20px] p-4 tracker mb-[8px]">
                <div className="flex items-center">
                  <div className="flex gap-[8px] items-center">
                    <div className="rounded-[4px] w-[48px] h-[48px]">
                      <img src={store} alt="product" className="object-cover rounded-[4px] w-[48px] h-[48px]" />

                    </div>
                    <p className="text-2xl font-semibold leading-6 text-primary underline underline-offset-3 cursor-pointer">Rice</p>
                  </div>


                </div>
                <div className="mt-4">
                  <p className="text-sm font-semibold leading-6 text-[#344335] mb-2">Agric Centre, University of Ibadan, Oyo State.</p>
                  <p className="text-primary text-2xl font-bold leading-6 mb-4">₦100,000/<span className="text-sm">Bag</span></p>
                  <div className="flex text-[#5C715E] font-semibold leading-5 justify-between gap-6 text-sm mb-4">
                    <p>01/01/2023 </p>
                    <p>By Adekunle Kunle</p>
                  </div>
                </div>
              </div>
              <div className="flex text-[#5C715E] text-sm font-medium leading-5">
                <div className="w-1/2 tracker_like px-2 py-[10px] flex  justify-center items-center gap-2 cursor-pointer">
                  <img src={thumb} alt="thumb" className="" onClick={() => setShow(true)} />

                  <p>120</p>
                </div>
                <div className="w-1/2 tracker_share px-2 py-[10px] flex justify-center items-center gap-2 cursor-pointer">
                  <img src={share} alt="share" className="" onClick={() => setShow(true)} />

                  <p>120</p>
                </div>
              </div>
            </div>

            <div className="mb-5 w-full" >
              <div className="mt-[20px] p-4 tracker mb-[8px]">
                <div className="flex items-center">
                  <div className="flex gap-[8px] items-center">
                    <div className="rounded-[4px] w-[48px] h-[48px]">
                      <img src={store} alt="product" className="object-cover rounded-[4px] w-[48px] h-[48px]" />

                    </div>
                    <p className="text-2xl font-semibold leading-6 text-primary underline underline-offset-3 cursor-pointer">Rice</p>
                  </div>


                </div>
                <div className="mt-4">
                  <p className="text-sm font-semibold leading-6 text-[#344335] mb-2">Agric Centre, University of Ibadan, Oyo State.</p>
                  <p className="text-primary text-2xl font-bold leading-6 mb-4">₦100,000/<span className="text-sm">Bag</span></p>
                  <div className="flex text-[#5C715E] font-semibold leading-5 justify-between gap-6 text-sm mb-4">
                    <p>01/01/2023 </p>
                    <p>By Adekunle Kunle</p>
                  </div>
                </div>
              </div>
              <div className="flex text-[#5C715E] text-sm font-medium leading-5">
                <div className="w-1/2 tracker_like px-2 py-[10px] flex  justify-center items-center gap-2 cursor-pointer">
                  <img src={thumb} alt="thumb" className="" onClick={() => setShow(true)} />

                  <p>120</p>
                </div>
                <div className="w-1/2 tracker_share px-2 py-[10px] flex justify-center items-center gap-2 cursor-pointer">
                  <img src={share} alt="share" className="" onClick={() => setShow(true)} />

                  <p>120</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddPost handleClick={isAuthenticated ? null : () => setShow(true)} />
      </div>

      <DrawerComponent show={show} showModal={() => setShow(false)} />



    </>
  );
};

export default TrackerPage;
