import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import back from "../../assets/back.svg";
import {useSelector, useDispatch} from 'react-redux'
import minus_icon from '../../assets/minus.svg'
import minus from '../../assets/minus_active.svg'
import plus_icon from '../../assets/plus_icon.svg'
import plus_active from '../../assets/plus_active.svg'
import input_img from '../../assets/input_img.svg'
import { addItemsToCart, getCartItems, UpdateCartQantity } from "../../redux/Cart/cartActions";
import { ProcessLoad } from "../../redux/Cart/cart";
import NavbarComp from "../../components/Navbar/navbar";
import cogoToast from "cogo-toast";
import BottomTabComp from "../../components/BottomTab/bottomTab";
import SimilarCard from "../../components/ProductCard/SimilarCard";
import { getProductById, getSimilarProducts } from "../../redux/Product/productActions";


function ProductDetails() {

  const {id} = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { similarproducts, loading, product } = useSelector((state) => state.product)
  const { addCartLoader, cart, processloader } = useSelector((state) => state.cart)
  const { isAuthenticated, userInfo} = useSelector((state) => state.auth);


  // let product = products.find((val) => val.id === id)

  const userId = userInfo && userInfo.id ? userInfo.id: 1

  const backFunc = () => {
    navigate("/");
  };
  const [showDets, setShowDets] = useState(false);


  const InCartCheck = (type, productId) =>{
    let typeTag = type ? type.toUpperCase() : type
    const inCart = cart.some((item) =>
    item.item_type === typeTag && item.product_id === parseInt(productId) ? true : false)
    return inCart
  }

  const CountCheck = (type, productId) =>{
    let typeTag = type  ? type.toUpperCase(): type
    let count = cart.find((item)=> item.item_type === typeTag && item.product_id === parseInt(productId))
    let countVal = count ? count.quantity : 1
    return countVal;
  }

  const IDCheck = (type, productId) =>{
    let typeTag = type ? type.toUpperCase(): type
    let id = cart.find((item)=> item.item_type === typeTag  && item.product_id === parseInt(productId))
    let idVal = id ? id.id : -1
    let res = processloader.indexOf(idVal)
    return res;
  }


  const increment = (type, productId) =>{

    // dispatch and increase quantity
     let cartId = cart.find((item)=> item.item_type === type.toUpperCase() && item.product_id === parseInt(productId)).id
     let quantity = cart.find((item)=> item.item_type === type.toUpperCase() && item.product_id === parseInt(productId)).quantity

     dispatch(ProcessLoad(cartId))

     dispatch(UpdateCartQantity(
      {
        id:cartId,
        quantity: quantity + 1,
      }))
  }

  const decrement = (type, productId) =>{
    let typeTag = type ? type.toUpperCase(): type
    let cartId = cart.find((item)=> item.item_type === typeTag && item.product_id === parseInt(productId)).id
    let quantity = cart.find((item)=> item.item_type === typeTag && item.product_id === parseInt(productId)).quantity

    dispatch(ProcessLoad(cartId))

    dispatch(UpdateCartQantity(
      {
        id:cartId,
        quantity: quantity - 1,
      }))
  }

  const handleAddCart = () =>{
    if(isAuthenticated){
      let result = {
        productType: product ? product.product_type : "CROP",
        quantity: 1,
        productId: product ? product.id : "",
        vendorId: product ? product.organization_id : "",
        vendorName: product ? product.organization_name: "",
        productPrice: product ? product.display_price : 0,
      }
      dispatch(addItemsToCart({result}))
    }
    else{
      cogoToast.info('Please sign in or create an account with us!')
      navigate('/login')
    }
    
  }

  useEffect(()=>{
    if(product && product.product_category_id){
      dispatch(getSimilarProducts({
        id: product ? product.product_category_id : ''
      }))
    }
  },[product, dispatch])

  useEffect(()=>{
    dispatch(getProductById({
      id: id
    }))
    if(isAuthenticated){
      dispatch(getCartItems({
        id:userId
      }))
    }
  },[dispatch, isAuthenticated, userId, id])

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    <div className="px-4 md:px-10 pt-6 pb-20 bg-[#f5f5f5] min-h-screen">
           <NavbarComp />
      <div className="flex text-center mt-8">
        <span
          className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer"
          onClick={() => backFunc()}
        >
          <img src={back} alt="back" />
        </span>
        <h6 className="mx-auto font-semibold text-base md:text-base">Product Details</h6>
      </div>
      <div className="mt-10">
        {
          product && product.images 
          ?
         product.images !== null && product.images.length > 0 
          ?
          <div className="max-w-[30em] max-h-full">
             <img className="rounded-md" src={product.images[0]} alt="product" />
         </div>
          :
          <div
            className='bg-[#E6E6E6] rounded-md w-[300px] md:h-[200px] h-[150px] flex justify-center items-center cursor-pointer'>
              <img 
              src={input_img} alt="item" className='rounded-lg w-[40px] h-[40px]' />
            </div>
          :
        <div
          className='bg-[#E6E6E6] rounded-md w-[300px] md:h-[200px] h-[150px] flex justify-center items-center cursor-pointer'>
            <img 
            src={input_img} alt="item" className='rounded-lg w-[40px] h-[40px]' />
          </div>
        }
       
       
        <h6 className="text-base font-bold text-[#344335] mt-2">{product ? product.name: ''} ({product ? product.product_size: ''}{product ? product.measurement: ''})</h6>
        <p className="text-[14px] text-[#344335]">{product ? product.organization_name: ""}</p>
        <p className="text-[14px] text-[#7C8D7E]">{product ? product.product_category ? product.product_category.name: 'Category' : 'Category'}</p>
        <p className="font-semibold text-[14px] text-[#344335]">₦{product && product.display_price ? (product.display_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : 0}</p>

        {/* add to cart btn */}
        {
          product && product.is_visible
          ?
          (InCartCheck(product.product_type, product.id) 
          ?
          <div className='max-w-[150px] mt-3'>
              <div className='flex justify-between items-center bg-[#ECEFEC] rounded-lg py-4 px-4'>
                  <div 
                    className={
                      IDCheck(product.product_type, product.id) !== -1 ||
                      CountCheck(product.product_type, product.id) === 1 ?
                      'pointer-events-none'
                      :
                      'cursor-pointer'
                    }
                    onClick={() => decrement(product.product_type, product.id)}
                    >
                    <img 
                     src={
                      IDCheck(product.product_type, product.id) !== -1 || CountCheck(product.product_type, product.id) === 1 ? minus_icon : minus}
                        alt="minus" className="w-[14px] h-[14px]" />
                  </div>
                  <div>
                    <p className='text-[#344335] text-sm font-semibold'>
                    {CountCheck(product.product_type, product.id)}
                    </p>
                  </div>
                  <div 
                  className={
                    IDCheck(product.product_type, product.id) !== -1 ?
                    'pointer-events-none'
                    :
                    'cursor-pointer'
                  }
                   onClick={() => increment(product.product_type, product.id)}
                  >
                    <img 
                    src={
                      IDCheck(product.product_type, product.id) !== -1 ? plus_icon : plus_active}
                    className="w-[14px] h-[14px]"  alt="plus" />
                  </div>
              </div> 
          </div> 
          :    
          <div className='mt-3'>
            <div>
                <button 
                 onClick={() => handleAddCart()}
                 disabled={addCartLoader}
                className='bg-primary
                disabled:bg-[#ABB6AC] 
                hover:bg-[#24752B] transition-all
                font-medium
                w-full md:w-auto
                  rounded-lg text-white py-4 px-8 text-sm'>Add to Cart</button>
              </div>
           </div>)
          :
          <div className='mt-3'>
          <div>
              <button 
               disabled
              className='bg-primary
              disabled:bg-[#ABB6AC] 
              hover:bg-[#24752B] transition-all
              font-medium
              w-full md:w-auto
                rounded-lg text-white py-4 px-8 text-sm'>Out Of Stock</button>
            </div>
         </div>
        }
          

       

        <h6 className="text-base font-semibold text-[#344335] mt-5">Product Info</h6>
        <div className="rounded-lg bg-[#ECEFEC] py-4 md:py-6 px-4 mt-2">
          <p className="text-[#344335] font-medium text-sm">
          {product ? product.description: ''}
          </p>
          <span
            className="text-primary font-semibold mt-2 text-[14px] hover:cursor-pointer"
            onClick={() => setShowDets(!showDets)}
          >
            {showDets ? "Hide Description" : "See full description"}
          </span>
          {showDets && (
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 my-3">
              <div>
                <h4 className="font-semibold text-[14px]">Vendor</h4>
                <p className="text-[#344335] font-medium text-sm">Solocad</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">Size</h4>
                <p className="text-[#344335] font-medium text-sm">{product ? product.product_size: ''}{product ? product.measurement: ''}</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">Product Class</h4>
                <p className="text-[#344335] font-medium text-sm">{product ? product.product_class: ''}</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">
                  Active Ingredients
                </h4>
                <p className="text-[#344335] font-medium text-sm">{product ? product.active_ingredient: ''}</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">Dose Rate</h4>
                <p className="text-[#344335] font-medium text-sm">{product ? product.rate: 0}</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">Target Crops</h4>
                <p className="text-[#344335] font-medium text-sm">All Crops</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">
                  Time of application
                </h4>
                <p className="text-[#344335] font-medium text-sm">{product ? product.time_of_application: ''}</p>
              </div>
              <div>
                <h4 className="font-semibold text-[14px]">
                  Mixing and application procedure
                </h4>
                <p className="text-[#344335] font-medium text-sm">
                  {product ? product.procedure: ''}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <h4 className="font-semibold mt-5">Similar Products</h4>
        <div className="my-3 grid grid-cols-2 gap-4 md:grid-cols-4 lg:auto-cols-auto">
          <SimilarCard />
        </div>
        {
         !loading && similarproducts.length === 0
          &&
          <div className="text-center mt-10">
            <p>There are no similar products available for display</p>
            </div>
        }
    </div>
      {/* footer */}
      <BottomTabComp />
    </>
  );
}

export default ProductDetails;
