import React,{useState, useEffect} from 'react'
import logo from '../../assets/crop2cash.svg'
import { Link, useNavigate } from 'react-router-dom'
import back from "../../assets/back.svg";
import PinInput from "react-pin-input";
import { useSelector, useDispatch } from 'react-redux';
import { requestOTP, verifyOTPReg } from '../../redux/Auth/authActions';
import { clearOTpSent, clearOtpVerified } from '../../redux/Auth/auth';

const VerifyOTPPage = () => {

    const navigate = useNavigate()
    const [pin, setPin] = useState("");
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const [valid, setValid] = useState(false)

    const {verifycreds,otpVerified, isOTPSent, loading} = useSelector((state) => state.auth);
    const dispatch = useDispatch()


    const handlePin = (val) => {
      setValid(false)
        setPin(val);
      };

  
  // Format minutes and seconds with leading zeros
  const formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    const backFunc = () => {
      navigate("/signup");
    };


    const resendCode = () =>{
       
        let phone  = verifycreds ? verifycreds.phone_number : ''
        dispatch(requestOTP({
          phone_number: '+234' + phone.substring(1)
        }))
    }

    useEffect(()=>{
      if(isOTPSent){
        setMinutes(1);
        setSeconds(0);
      }
      setTimeout(()=>{
        dispatch(clearOTpSent())
      },2000)
    },[isOTPSent, navigate, dispatch])
  
    const handleSubmit = () => {
      // validate for no entry or not complete entry
      if(pin === "" || pin.length < 4){
        setValid(true)
        }
        else{
          let phone  = verifycreds ? verifycreds.phone_number : ''
          dispatch(verifyOTPReg({
            otp: pin,
            phone_number: '+234' + phone.substring(1)
          }))
      }
    }

    useEffect(()=>{
      if(otpVerified){
        navigate('/onboard/basic-info')
      }
      setTimeout(()=>{
        dispatch(clearOtpVerified())
      },2000)
    },[navigate, otpVerified, dispatch])


    useEffect(() => {
        const interval = setInterval(() => {
          if (minutes === 0 && seconds === 0) {
            clearInterval(interval);
            // Handle timer expiration here
          } else if (seconds === 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            setSeconds(seconds - 1);
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [minutes, seconds]);

  


    return ( 
        <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#FBEB96] w-[450px] h-[450px] rounded-full blur-[200px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* signup section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-24 py-10">

         <div className='flex justify-between items-center'>
                <div>
                    <div 
                       onClick={() => backFunc()}
                        className="bg-[#ECEEEC] w-[24px] h-[24px] rounded-full flex items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer">
                        <img src={back} alt="back" />
                    </div>
                </div>
               
            </div>

          <div className='mt-4'>
            <h6 className="text-[#344335] font-semibold text-xl">
            Enter the 4 digit code
            </h6>
            <p className="text-[#5C715E] font-medium text-sm mt-1">
            Hey, we sent a 4-digit code to <span className='font-semibold'>{verifycreds ? '234'+ verifycreds.phone_number : ''}</span>
            </p>
          </div>

          < div className='text-center mt-8'>
              <PinInput
                length={4}
                initialValue={pin}
                focus
                
                type="numeric"
                onChange={handlePin}
                inputStyle={{ borderColor: "#96A397" }}
                inputFocusStyle={{ borderColor: "#2B8C34" }}
                />
                 {valid && 
                  <small style={{ color: "rgb(220, 53, 69)" }}>
                      Code is required!
                      </small>
                      }
            </div>

          {
               minutes === 0 && seconds === 0 
               ?
             <div className='text-center mt-4'>
             <button disabled={loading} onClick={resendCode} className='text-[#2B8C34] font-medium text-sm disabled:text-[#ABB6AC]'>Resend code</button>
            </div>
            :   
            <div className='text-center mt-4'>
                <p className='text-[#5C715E] font-medium text-sm'>Resend code in <span className='text-[#161D17]'>{formattedTime}</span></p>
            </div>
          }  
          


            <div className="mt-6">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                   Continue
                  </button>
            </div>


        </div>
      </div>
        </>
     );
}
 
export default VerifyOTPPage;