import React from 'react'
import back from "../../assets/back.svg";
import checkbox_white from "../../assets/checkbox_white.svg";
import NavbarComp from '../../components/Navbar/navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TrackOrderPage = () => {

    const navigate = useNavigate()
    const {orders} = useSelector((state) => state.order);

    const query = useParams();
    let id = query.id

    let order = orders.find((val) => val.id === id)


    const backFunc = () => {
        navigate(`/order/${id}`);
     };


    return ( 
        <>
            <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
                <NavbarComp />
                <div className="flex text-center mt-8">
                    <span
                    className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-secondary1 hover:cursor-pointer"
                    onClick={() => backFunc()}
                    >
                    <img src={back} alt="back" />
                    </span>
                    <h6 className="mx-auto font-semibold text-base md:text-base">Track Order</h6>
                </div>

                <div className="md:max-w-lg lg:max-w-xl md:mt-5 mt-3 mx-auto md:py-10 py-10">

                        <ol class="relative border-l-2 ml-2 border-[#ABB6AC]">  

                          {
                            order && order.order_status.length > 0 ?
                            order.order_status.map((item) =>(
                              <li class="mb-14 ml-6 flex justify-center flex-col">
                                <div class="absolute -left-3">
                                    <div className={
                                        item === "CANCELLED" ? 'bg-[#B92043] flex justify-center items-center w-[24px] h-[24px] rounded-full' :
                                        'flex justify-center items-center w-[24px] h-[24px] bg-[#2B8C34] rounded-full'
                                    }>
                                    <img src={checkbox_white} alt="check" />
                                    </div>
                                </div>
                                <h6 class="text-sm font-semibold text-[#344335]">{item}</h6>
                                {/* <p className='text-[#96A397] text-sm font-medium'>12 Aug 12, 10:12 am </p> */}
                            </li>
                            ))
                            :
                            ""
                          }  
                    </ol>

                </div>
                
            </div>
        </>
     );
}
 
export default TrackOrderPage;