import React from "react";
import { useLocation, Link } from "react-router-dom";
import storefront_icon from "../../assets/storefront.svg";
import storefront_active from "../../assets/storefront_active.svg";
import shopping_bag from "../../assets/shopping_bag.svg";
import shopping_bag_active from "../../assets/shopping_bag_active.svg";
import person_filled from "../../assets/person_filled.svg";
import person_filled_active from "../../assets/person_filled_active.svg";
import account_balance_wallet from "../../assets/account_balance_wallet.svg";
import account_balance_wallet_active from "../../assets/account_balance_wallet_active.svg";
// import tracker from "../../assets/monitoring.svg";
// import tracker_active from "../../assets/monitoring_active.svg";
import { useSelector } from "react-redux";

const BottomTabComp = () => {
  const location = useLocation();

  const { cart } = useSelector((state) => state.cart);
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <>
      <div className="bg-white shadow-[bottomTab] md:hidden flex bottom-0 fixed justify-between w-full z-[1000] gap-1 items-center pr-4">
        <Link
          to="/"
          className="flex justify-center items-center flex-col px-4 py-4"
        >
          <img
            src={
              location.pathname === "/" ||
              location.pathname.includes("/product")
                ? storefront_active
                : storefront_icon
            }
            alt="storefront_icon"
            className="w-[20px] h-[20px]"
          />
          <p
            className={
              location.pathname === "/" ||
              location.pathname.includes("/product")
                ? "text-[#2B8C34] font-semibold text-xs mt-1"
                : "text-[#5C715E] font-semibold text-xs mt-1"
            }
          >
            MarketPlace
          </p>
        </Link>
        <Link
          to="/cart"
          className="flex justify-center items-center flex-col px-4 py-4"
        >
          <div className="relative">
            <img
              src={
                location.pathname === "/cart"
                  ? shopping_bag_active
                  : shopping_bag
              }
              alt="shopping_bag"
              className="w-[20px] h-[20px]"
            />
            {cart && cart.length > 0 && (
              <div className="bg-[#B92043] rounded-[3px] w-[12px] h-[14px] absolute top-0 -right-1 flex justify-center items-center">
                <p className="text-white text-[10px] font-medium">
                  {cart.length}
                </p>
              </div>
            )}
          </div>

          <p
            className={
              location.pathname === "/cart"
                ? "text-[#2B8C34] font-semibold text-xs mt-1"
                : "text-[#5C715E] font-semibold text-xs mt-1"
            }
          >
            Cart
          </p>
        </Link>

        {/* <Link
          to="/tracker"
          className="flex justify-center items-center flex-col px-4 py-4"
        >
          <img
            src={location.pathname === "/tracker" ? tracker_active : tracker}
            alt="tracker"
            className="w-[20px] h-[20px]"
          />

          <p
            className={
              location.pathname === "/tracker"
                ? "text-[#2B8C34] font-semibold text-xs mt-1"
                : "text-[#5C715E] font-semibold text-xs mt-1"
            }
          >
            Tracker
          </p>
        </Link> */}

        {isAuthenticated ? (
          <Link
            to="/wallet"
            className="flex justify-center items-center flex-col px-4 py-4"
          >
            <img
              src={
                location.pathname === "/wallet" ||
                location.pathname.includes("/send-money")
                  ? account_balance_wallet_active
                  : account_balance_wallet
              }
              alt="wallet"
              className="w-[20px] h-[20px]"
            />
            <p
              className={
                location.pathname === "/wallet" ||
                location.pathname.includes("/send-money")
                  ? "text-[#2B8C34] font-semibold text-xs mt-1"
                  : "text-[#5C715E] font-semibold text-xs mt-1"
              }
            >
              Wallet
            </p>
          </Link>
        ) : (
          ""
        )}
      {isAuthenticated ? (
          <Link
            to="/account"
            className="flex justify-center items-center flex-col px-4 py-4"
          >
            <img
              src={
                location.pathname === "/account" ||
                location.pathname.includes("/profile")
                  ? person_filled_active
                  : person_filled
              }
              alt="person_filled"
              className="w-[20px] h-[20px]"
            />
            <p
              className={
                location.pathname === "/account" ||
                location.pathname.includes("/profile")
                  ? "text-[#2B8C34] font-semibold text-xs mt-1"
                  : "text-[#5C715E] font-semibold text-xs mt-1"
              }
            >
              Account
            </p>
          </Link>
      ): (
        ""
        )}
      </div>
    </>
  );
};

export default BottomTabComp;
