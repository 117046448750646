import React from 'react'
// import logo from '../../assets/crop2cash.svg'
import logo1 from '../../assets/newLogo.svg'
import cart_icon from '../../assets/cart_green_icon.svg'
import user_icon from '../../assets/user.svg'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavbarComp = () => {

    const { userInfo, isAuthenticated } = useSelector((state) => state.auth)
    const { cart } = useSelector((state) => state.cart)

    return ( 
        <>
            <div className='flex justify-between items-center'>
                <Link to="/">
                    <img src={logo1} alt="logo" className="md:w-[200px] w-[150px] h-[40px]" />
                </Link>
                <div className='flex gap-4'>
                    <Link to="/cart" className='hidden md:flex justify-center items-center bg-[#DBFFDF] rounded-[4px] w-[40px] h-[40px] relative'>
                        <img src={cart_icon} className="w-[16px] h-[16px]" alt="cart icon" />
                        {
                          cart && cart.length > 0 &&
                            <div className='bg-[#B92043] rounded-[3px] w-[12px] h-[14px] absolute top-0 -right-1 flex justify-center items-center'>
                            <p className='text-white text-[10px] font-medium'>{cart.length}</p>
                            </div>
                        }
                    </Link>
                    <div className='flex items-center gap-2'>
                        {
                            isAuthenticated ?
                            <Link to="/account" className='flex justify-center items-center bg-[#DBFFDF] rounded-full w-[40px] h-[40px] relative'>
                            <p className='text-[#2B8C34] font-medium text-sm'>
                            {userInfo && userInfo.first_name ? userInfo.first_name.charAt(0).toUpperCase() : 'A'}{userInfo && userInfo.last_name ? userInfo.last_name.charAt(0).toUpperCase() : 'K'}</p>
                            <span className='w-[6px] h-[6px] rounded-full bg-[#B92043] absolute top-1 right-0'></span>
                        </Link>
                        :
                          <div className='flex justify-center items-center bg-[#DBFFDF] rounded-full w-[40px] h-[40px]'>
                                <img src={user_icon} alt="user" className='w-[16px] h-[16px]' />
                            </div>
                        }  
                        <div>
                            {
                                isAuthenticated ?
                                <p className='text-[#344335] text-sm font-semibold'>
                                 {userInfo && userInfo.first_name ? userInfo.first_name : 'N/A'}</p>
                                :
                                <Link to="/login" className='text-[#344335] text-sm font-semibold'>Login/Sign Up</Link>
                            }
                          
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default NavbarComp;