import React,{useEffect, useState} from 'react'
import { Form, Formik } from "formik";
import { locationValidator } from "../../validationSchema/validator";
import minus_icon from '../../assets/minus.svg'
import minus from '../../assets/minus_active.svg'
import plus_icon from '../../assets/plus_icon.svg'
import plus_active from '../../assets/plus_active.svg'
import arrow_down from '../../assets/arrow-down.svg'
import line_icon from '../../assets/Line.svg'
import cart_icon from '../../assets/cart_icon.svg'
import Modal from '../../components/Modals/modal'
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, deleteFarmersCart, getCartItems, UpdateCartQantity } from '../../redux/Cart/cartActions';
import { Link, useNavigate } from 'react-router-dom';
import { getLGAByStateID, getStates } from '../../redux/Auth/authActions';
import input_img from '../../assets/input_img.svg'
import PinInput from "react-pin-input";
import NavbarComp from '../../components/Navbar/navbar';
import { clearOrderPlaced, ProcessLoad } from '../../redux/Cart/cart';
import BottomTabComp from '../../components/BottomTab/bottomTab';
import cogoToast from 'cogo-toast';
import warning from '../../assets/warning.svg'

const CartPage = () => {

    const [show, setShow] = useState(false)
    const [showCodeModal, setShowCodeModal] = useState(false)
    const [pin, setPin] = useState("");
    const [valid] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { cart, createLoader, isOrderPlaced, processloader  } = useSelector((state) => state.cart)
    const {states, lgas, isAuthenticated, userInfo, account_number} = useSelector((state) => state.auth);

    const userId = userInfo && userInfo.id ? userInfo.id: 1
    const handlePin = (val) => {
      setPin(val);
    };

    const showModal = () =>{
        setShow(!show)
      }

    const codeModal = () =>{
        setShowCodeModal(!showCodeModal)
    }

    const [deliveryInfo, setDeliveryInfo] = useState({})
    const handleCheckout = (values) =>{
        if(account_number === null){
          cogoToast.info("You don't have an account to place this order, please check the status of your request!")
          setTimeout(()=>{
              navigate('/wallet')
          },2000)
        }
        else{
          if( formatWalletBalance() < getTotalAmount().totalPrice){
            cogoToast.info("You don't have enough balance to place this order, kindly fund your wallet!")
          }
          else{
            setShow(true)
            setDeliveryInfo(
              {
                ...values,
                stateName: states.find((val)=> val.id === values.stateID).name,
                lgaName: lgas.find((val)=> val.id === values.lgaID).name,
                
              }
            )
          }
         
        }  
    }

    const handleDelete = (id) =>{
      dispatch(deleteFarmersCart({
        id
      }))
    }

    const formatWalletBalance = () =>{
        let res;
        if(userInfo && userInfo.balance){
          res = userInfo.balance/100
        }
        else{
          res = 0
        }
        return res;
    }

      // ------- cart total amount calculation
   const getTotalAmount = () => {
    let totalPrice = 0
    cart.forEach(item => {
      totalPrice += item.total_item_price * item.quantity
    })
    return {
      totalPrice
    }
  }

  const handleState = (val)=>{
    // make api call to get LGAS under state
    dispatch(getLGAByStateID({
      id: val
    }))
  }

  const increment = (cartId, quantity) =>{
    dispatch(ProcessLoad(cartId))

    dispatch(UpdateCartQantity(
      {
        id:cartId,
        quantity: quantity + 1,
      }))
  }

  const decrement = (cartId, quantity) =>{
    dispatch(ProcessLoad(cartId))

    dispatch(UpdateCartQantity(
      {
        id:cartId,
        quantity: quantity - 1,
      }))
  }

  const handleConfirmOrder = () =>{
    setShow(false)

    setTimeout(()=>{
        setShowCodeModal(true)
    },500)
  }

  const handlePayment = () =>{
     // makes an api call to checkout the order added to cart for the farmer
     let resp = {
      totalPrice: getTotalAmount().totalPrice,
      stateID: deliveryInfo.stateID,
      lgaID: deliveryInfo.lgaID,
      deliveryAddress: deliveryInfo.deliveryAddress,
      pin: pin
    }
    dispatch(createOrder({resp}))
  }

  useEffect(()=>{
    if(isOrderPlaced){
      setTimeout(()=>{
        setShowCodeModal(false)
        dispatch(clearOrderPlaced())
        navigate('/wallet')
      },1000)
    }
  },[isOrderPlaced, dispatch, navigate])

    useEffect(()=>{
      if(isAuthenticated){
        dispatch(getCartItems({
          id:userId
        }))
      }
      dispatch(getStates())
    },[dispatch, isAuthenticated, userId])


    return ( 
        <>

     {/* checkout confirmation modal */}
    <Modal show={show} showModal={showModal} 
       title="Confirm Order"
       subtitle="Please confirm the order before sending it"
       >
            {/* products display and address */}
            <div className='mt-6'>
              <div className='bg-[#F7F7F7] rounded-lg py-3 px-3'>
                <p className='text-[#96A397] text-xs font-medium'>Delivery Address</p>
                <h4 className='text-[#344335] text-sm font-medium'>{deliveryInfo && deliveryInfo.deliveryAddress ? deliveryInfo.deliveryAddress: ''}</h4>
                <h6 className='text-[#344335] text-xs font-medium'>{deliveryInfo && deliveryInfo.stateName ? deliveryInfo.stateName: ''} | {deliveryInfo && deliveryInfo.lgaName ? deliveryInfo.lgaName: ''}</h6>
              </div>

                {/* items */}

                <div className='bg-[#ECEEEC] w-full h-[2px] mt-5'></div>

                <div className='flex justify-between mt-2'>
                    <div>
                    <p className='text-[#344335] font-semibold text-sm md:text-base'>{cart.length} Item(s)</p>
                    </div>
                    <div className=''>
                        <h4 className='text-primary font-semibold text-sm md:text-base'>₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
                     </div>
                </div>
                
                <div className='mt-8'>
                  <button 
                  onClick={handleConfirmOrder}
                     disabled={false}
                    className='bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg w-full text-white py-3 px-6 text-sm'>Confirm Order</button>
                </div>
            </div>
     </Modal>

      {/* confirm payment */}
      <Modal show={showCodeModal} showModal={codeModal} 
       title="Confirm Payment"
       subtitle="Enter your 4 digit pin to confirm payment"
       >
            {/* products display and address */}
            <div className='mt-10'>
             {/* pin layout */}
              <div className='text-center'>
                  <PinInput
                    length={4}
                    initialValue={pin}
                    focus
                    secret
                    type="numeric"
                    onChange={handlePin}
                    inputStyle={{ borderColor: "#96A397" }}
                    inputFocusStyle={{ borderColor: "#2B8C34" }}
                  />
                    <small className="text-[#B92043] font-medium text-xs" >
                      {valid ? "Incorrect Pin!": ""}
                    </small>
                </div>

              
                <div className='mt-12'>
                  <button 
                  onClick={handlePayment}
                     disabled={createLoader}
                    className='bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg w-full text-white py-4 px-6 text-sm'>Pay ₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</button>
                </div>
            </div>
     </Modal>

           <div className="px-4 md:px-10 pt-6 pb-28 bg-[#f5f5f5] min-h-screen relative">
              <NavbarComp />
                <div className='mt-8'>
                    <h6 className='text-[#344335] font-bold text-lg md:text-2xl'>Cart</h6>
                </div>

                <div className='bg-[#ECEEEC] rounded-lg py-4 px-6 mt-5 flex justify-between'>
                    <div>
                        <p className='text-[#344335] font-semibold text-sm md:text-base'>{cart.length} Item(s)</p>
                    </div>
                    <div>
                        <p className='text-[#7C8D7E] font-medium text-sm md:text-base'>
                            Subtotal: <span className='text-[#344335] font-semibold text-sm md:text-base'>₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                        </p>
                    </div>
                </div>

                {
                 cart && cart.length > 0 ? 
                  <div className='mt-5'>
                    <div className='grid md:grid-cols-3 gap-4'>
                      <div className='bg-white rounded-lg py-6 px-6 md:col-span-2 h-fit'>
                        <p className='text-[#7C8D7E] text-sm font-semibold'>Item Details</p>   

                         {
                            cart && cart.length > 0 ?
                            cart.map((item, index) =>(    
                              <>
                            <div  key={index}  className='flex mt-5 mb-6 items-start'>
                              <div className='flex'>
                                    <div className='flex gap-4'>
                                      {
                                      item.images && item.images.length > 0
                                          ?
                                         <div className=''>
                                            <img src={item.images[0]} alt="item" className='rounded-lg w-[100px] h-[100px]' />
                                        </div>
                                          :
                                          <div className='bg-[#E6E6E6] rounded-md w-[100px] h-[100px] flex justify-center items-center'>
                                             <img src={input_img} alt="item" className='rounded-lg w-[40px] h-[40px]' />
                                          </div>
                                        }
                                        <div>
                                        <h6 className='text-[#344335] text-sm font-semibold'>{item.product_name ? item.product_name: 'N/A'}</h6>
                                        <p className='text-[#344335] text-sm mt-1'>
                                        {item.vendor_name}
                                        </p>
                                        <p className='text-[#344335] text-sm mt-1'>
                                        {item.product_size}{item.product_measurement}
                                        </p>
                                        <h6 className='text-[#344335] text-sm font-medium mt-1'>₦{item.total_item_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h6>

                                        {/* quantity counter */}
                                        <div className='flex items-center gap-2 mt-2'>
                                          <div>
                                            <div className='flex justify-between items-center min-w-[108px] bg-[#ECEFEC] rounded-lg py-2 px-3 '>
                                                <div 
                                                className={        
                                                  item.quantity === 1 || processloader.indexOf(item.id)!== -1 ?
                                                  'pointer-events-none'
                                                  :
                                                  'cursor-pointer'
                                                }
                                                 onClick={() => decrement(item.id, item.quantity)}
                                                  >
                                                  <img src={
                                                    item.quantity === 1 ||  processloader.indexOf(item.id)!== -1  ? minus_icon : minus}
                                                      alt="minus" className="w-[14px] h-[14px]" />
                                                </div>
                                                <div>
                                                  <p className='text-[#344335] text-sm font-semibold'>{item.quantity}</p>
                                                </div>
                                                <div
                                                className={        
                                                  processloader.indexOf(item.id)!== -1 ?
                                                  'pointer-events-none'
                                                  :
                                                  'cursor-pointer'
                                                }
                                                onClick={() => increment(item.id, item.quantity)}
                                                >
                                                  <img 
                                                    src={
                                                      processloader.indexOf(item.id)!== -1 ? plus_icon : plus_active}
                                                    className="w-[14px] h-[14px]"  alt="plus" />
                                                </div>
                                            </div>
                                            </div>
                                            <img src={line_icon} alt="line" />
                                            <button 
                                               onClick={() => handleDelete(item.id)}
                                              disabled={false}
                                            className='text-[#7C8D7E] 
                                            disabled:text-[#7C8D7E] disabled:opacity-[0.4]
                                            text-sm font-medium'>Remove</button>
                                        </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                           {cart.length > 1 && <div className='bg-[#CED5CF] w-full h-[0.8px]'></div> }
                            </>
                             ))
                             :
                             ""
                           }
                      </div>

                      <div>
                        
                      <Formik
                        onSubmit={(values, { setSubmitting }) =>
                              handleCheckout(values, setSubmitting)
                            }
                            enableReinitialize={true}
                            validationSchema={locationValidator}
                            initialValues={{
                              stateID: "",
                              lgaID: "",
                              deliveryAddress: ""
                            }}
                          >
                            {({
                              handleChange,
                              isSubmitting,
                              handleSubmit,
                              handleBlur,
                              values,
                              touched,
                              errors,
                              setFieldValue
                          }) => (
                        <Form onSubmit={handleSubmit}>
                          {/* delivery address section */}
                          <div className='bg-white rounded-lg py-6 px-4'>
                            
                            <label>
                                  <span className="after:content-['*'] 
                                    after:ml-0.5 after:text-[#B92043] text-[#7C8D7E] text-sm font-semibold">
                                      Delivery Address
                                    </span>
                              </label>
                        
                            <div className="grid md:grid-cols-2 md:gap-6 mt-4">
                              <div>
                                <div className='relative'>
                                <select
                                  name="stateID"
                                  defaultValue=""
                                  placeholder="Choose a State"
                                  className='appearance-none cursor-pointer w-full placeholder:text-[#96A397]
                                  placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34]
                                    focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-3'
                                      value={values.stateID}
                                      onBlur={handleBlur}  
                                      onChange={(e) => {
                                        handleChange(
                                            e,
                                            setFieldValue("lgaID", "")
                                          );
                                        handleState(e.currentTarget.value);
                                      }}
                                      >       
                                   <option value="" selected disabled>Choose a State</option>
                                    {states.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                      ))}
                                  </select>
                                    <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer'>
                                        <img src={arrow_down} alt="arrow" />
                                    </div>
                                    <small style={{ color: "#dc3545" }}>
                                    {touched.stateID && errors.stateID}
                                  </small>
                                </div>
                              </div>
                            <div>
                              <div className='relative mt-4 md:mt-0'>
                                <select
                                  name="lgaID"
                                  placeholder="Choose LGA"
                                  className='appearance-none cursor-pointer w-full
                                  placeholder:text-[#96A397] placeholder:text-sm text-[#344335]
                                    text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input
                                    rounded-lg border border-[#96A397] bg-white py-3 px-3'
                                    value={values.lgaID}
                                    onChange={handleChange}
                                      onBlur={handleBlur}
                                      defaultValue=""
                                >
                                   <option selected value="" disabled>Choose LGA</option>
                                    {lgas.map((option) => (
                                  <option key={option.id} value={option.id}>{option.name}</option>
                                  ))}
                                </select>
                                  <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer'>
                                        <img src={arrow_down} alt="arrow" />
                                  </div>
                                  <small style={{ color: "#dc3545" }}>
                                    {touched.lgaID && errors.lgaID}
                                  </small>
                                </div>

                              </div>  
                            </div>

                            <div className='mt-5'>
                                <textarea id="deliveryAddress" rows="8" 
                                className='appearance-none w-full
                                placeholder:text-[#96A397] placeholder:text-sm
                                  text-secondary1
                                  text-sm
                                  focus:border-[#ABB6AC]
                                  focus:border outline-none
                                  rounded-lg resize-none
                                  bg-[#F7F7F7] py-3 px-4'
                                    value={values.deliveryAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  placeholder="Please fill the delivery address"></textarea>
                                   <small style={{ color: "#dc3545" }}>
                                    {touched.deliveryAddress && errors.deliveryAddress}
                                  </small>
                              </div> 

                          </div>

                          {/* checkout */}
                          <div className='bg-white flex justify-between items-center rounded-lg py-6 px-4 mt-5'>
                              <div>
                                <p className='text-[#7C8D7E] text-sm font-semibold'>Total Amount</p>
                                <h4 className='text-2xl text-primary font-semibold'>₦{getTotalAmount().totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h4>
                                
                                {
                                 formatWalletBalance() < getTotalAmount().totalPrice &&
                                <div className='flex gap-1 items-center'>
                                  <img src={warning} alt="refresh" className='cursor-pointer' />
                                  <p className='text-[#B92043] text-xs font-medium'>Insufficent Balance</p>
                                </div>
                                }

                              </div>
                              <div>
                                <button
                                type="submit"
                                  className="bg-primary
                                    disabled:bg-[#ABB6AC] 
                                    rounded-lg w-full py-3 px-6 text-white font-medium hover:bg-[#24752B] transition-all">Checkout</button>
                                  </div>
                          </div>
                          </Form>
                        )}
                      </Formik>

                      </div>

                    </div>
                    </div>
                  :
                  <div className='max-w-sm mx-auto'>
                  <div className='flex justify-center mt-20'>
                    <div className='bg-[#DBFFDF] w-[60px] h-[60px] rounded-full flex justify-center items-center'>
                      <img src={cart_icon} alt="cart" />
                    </div>
                    </div>
      
                    <div className='text-center mt-3'>
                      <p className='text-[#344335] text-xs font-medium'>Looks like we have an empty cart</p>
      
                      <p className='text-[#7C8D7E] text-xs font-medium mt-1'>
                      Add item(s) to cart by creating orders
                      </p>
                    </div>
      
                    <div className='flex justify-center mt-4'>
                      <Link to="/" className='
                            py-3 px-6 bg-primary rounded-[8px] text-white 
                            text-sm font-medium hover:bg-[#24752B] transition-all'>Continue Shopping</Link>
                    </div>
      
                </div>
                }


            </div>

            {/* footer */}
          <BottomTabComp />
        </>
     );
}
 
export default CartPage;