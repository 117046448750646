import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const getBanks = createAsyncThunk(
  'getBanks',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}banks`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getWalletTransactions = createAsyncThunk(
  'getWalletTransactions',
  async ({page}, {rejectWithValue, dispatch, getState}) => {
    const userId  = getState().auth.userInfo.id
    try {
  
      const res = await axios.get(
        `${apiUrl}transaction/${userId}?page=${page}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const verifyAccountNumber = createAsyncThunk(
  'verifyAccountNumber',
  async ({ bank_id, accountNumber }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}verify-account`,
        { 
         bank_id: bank_id,
         account_number: accountNumber,
         wallet_provider: "paystack"
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
          
      }
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getOTP = createAsyncThunk(
    'getOTP',
    async (arg, {rejectWithValue, dispatch}) => {
      try {
    
        const res = await axios.get(
          `${apiUrl}request-otp`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            cogoToast.success('OTP has been sent successfully!')
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)


export const verifyOTP = createAsyncThunk(
    'verifyOTP',
    async ({ otp }, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.get(
          `${apiUrl}verify-otp?otp=${otp}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
            
        }
      } catch (error) {
        if(error.response.status === 400){
          cogoToast.error("Wrong verification number!")
          rejectWithValue(error.response)
        }
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

export const processTransfer = createAsyncThunk(
    'processTransfer',
    async ({ amount, bank_id, account_name, account_number, pin, narration }, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.post(
          `${apiUrl}bank-transfer`,
          { 
            bank_id: bank_id,
            amount: amount,
            account_name: account_name,
            account_number: account_number,
            pin: pin,
            narration: narration
          },
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            cogoToast.success("Transfer processed succesfully!")
            return res.data
        }
      } catch (error) {
        if(error.response.status === 400){
          cogoToast.error(`${error.response.data.error}`)
          return rejectWithValue(error.response)
        }
        if(error.response.status === 500){
          cogoToast.error('Ooops!, Please try again later!')
          return rejectWithValue(error.response)
        }
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)



  




 
