import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './Auth/auth'
import productReducer from './Product/product'
import cartReducer from './Cart/cart'
import walletReducer from './Wallet/wallet'
import orderReducer from './Orders/orders'


const appReducer = combineReducers({
    auth: authReducer,
    product: productReducer,
    cart: cartReducer,
    wallet: walletReducer,
    order: orderReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'auth/Logout') {
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer

