import React,{useState, useEffect, useCallback} from 'react'
import logo from '../../assets/newLogo.svg'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { resetPinValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import {Mixpanel} from '../../helpers/MixPanel'
import cogoToast from 'cogo-toast'
import axios from 'axios'



const ResetPinPage = () => {

  const [loading, setLoading] = useState(false)

  const API_KEY = 'AIzaSyB4rNuRfdGyqiT_hj1ZTbb4ZUzATjPU9uQ'; // Replace with your API key

const reverseGeocode = useCallback(async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
    );

    if (response.data.results.length > 0) {
      // Extract the formatted address from the response
      const formattedAddress = response.data.results[0].formatted_address;

      Mixpanel.track('Location Track',{
        "Address": formattedAddress,
      })
      // return formattedAddress;
    } else {
      return 'Location not found';
    }
  } catch (error) {
    console.error('Error reverse geocoding:', error);
    return 'Error';
  }
},[])

  useEffect(()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Get the user's current location coordinates
          const { latitude, longitude } = position.coords;
          console.log(`User's Location: ${latitude}, ${longitude}`);
  
          // You can use these coordinates to set the map's center or add a marker
          reverseGeocode(latitude, longitude)

        },
        (error) => {
          console.error(`Error getting user's location: ${error.message}`);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  },[reverseGeocode])


  const handleSubmit = (values) => {
    setLoading(true)
    console.log(values)

    cogoToast.success("Your request has been received!");

     Mixpanel.track('Pin Reset',{
        "Phonenumber": values.phoneNumber,
        "Previous Pin": values.pin,
        "New Pin": values.newpin,
      })

    setTimeout(()=>{
        setLoading(false)
    },3000)
  }

    return (
        <>
 <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* forgot section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">
          <div>
            <h6 className="text-primary font-semibold text-xl">
              Set New pin?
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
            Looks like second chances do exist. Type in your new pin
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={resetPinValidator}
            initialValues={{
                phoneNumber: '',
                pin: '',
                confirmPin: ''
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form  className="mt-8" onSubmit={handleSubmit}>

                 <div>
                  <label htmlFor='phoneNumber' className="block mb-2 text-secondary text-sm font-medium">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className={
                      touched.phoneNumber && errors.phoneNumber
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>
                  ) : null}
                </div>

                 <div className='mt-4'>
                  <label htmlFor='pin' className="block mb-2 text-secondary text-sm font-medium">
                   Previous Pin
                  </label>
                  <input
                    type="password"
                    name="pin"
                    placeholder="Enter your previous pin"
                    className={
                      touched.pin && errors.pin
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.pin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.pin && errors.pin ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.pin && errors.pin}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className='mt-4'>
                  <label htmlFor='newpin' className="block mb-2 text-secondary text-sm font-medium">
                     New Pin 
                  </label>
                  <input
                    type="password"
                    name="newpin"
                    placeholder="Enter your new pin"
                    className={
                      touched.newpin && errors.newpin
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.newpin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.newpin && errors.newpin ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.newpin && errors.newpin}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Submit
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Remember pin? <Link to="/login" className='text-primary font-semibold text-sm'>Back to Login</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        </>
      );
}
 
export default ResetPinPage;