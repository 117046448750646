import { createSlice } from '@reduxjs/toolkit'
import {  getBanks, getOTP, getWalletTransactions, processTransfer, verifyAccountNumber, verifyOTP } from './walletActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  loading: false,
  accountName: null,
  validAccount: false,
  invalidAccount: false,
  isTranferSent: false,
  isValidOtp : false,
  verifyLoader: false,
  banks: [],
  transactions: [],
  count: 0,
  transferLoader: false
}


const walletPersistConfig = {
  key: 'wallet',
  storage,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    clearTransferSent: (state) =>{
        state.isTranferSent = false
        state.validAccount = false
        state.invalidAccount = false
        state.validAccount = null
        state.accountName = null
    },
    clearValidity: (state) =>{
        state.isValidOtp = false
    }
  },
  extraReducers: {
    // get banks
    [getBanks.pending]: (state) =>{

    },
    [getBanks.fulfilled]: (state, {payload}) =>{
        state.banks = payload.data
    },
    [getBanks.rejected]: (state) =>{

    },

    // get wallet transactions --------
    [getWalletTransactions.pending]:  (state) =>{
      state.loading = true
    },
    [getWalletTransactions.fulfilled]:  (state, {payload}) =>{
      state.loading = false
      state.transactions = payload.data
      state.count = payload.total
    },
    [getWalletTransactions.rejected] : (state) =>{
      state.loading = false
    },

    // Verify account number -------------------- ---------------------------------------------------------------
    [verifyAccountNumber.pending]: (state) => {
      state.loading = true
    },
    [verifyAccountNumber.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.validAccount = true
      state.invalidAccount = false
      state.accountName = payload.account_name        
    },
    [verifyAccountNumber.rejected]: (state, { payload }) => {
      state.loading = false
      state.validAccount = false
      state.invalidAccount = true
      state.accountName = null        
    },

    // get OTp
    [getOTP.pending]:  (state) =>{

    },
    [getOTP.fulfilled]: (state) =>{

    },
    [getOTP.rejected]:  (state) =>{

    },

    // veriy otp
    [verifyOTP.pending]: (state) =>{
        state.verifyLoader = true
    },
    [verifyOTP.fulfilled]: (state) =>{
        state.isValidOtp = true
        state.verifyLoader = false
    },
    [verifyOTP.rejected]:  (state) =>{
        state.isValidOtp = false
        state.verifyLoader = false
    },
    
    // ....process transfers
    [processTransfer.pending]: (state) =>{
        state.transferLoader = true
    },
    [processTransfer.fulfilled]: (state) =>{
        state.isTranferSent = true
        state.transferLoader = false
    },
    [processTransfer.rejected]: (state,{payload}) =>{
        state.isTranferSent = false
        state.transferLoader = false
    },
    
  }
})

const walletReducer = persistReducer(walletPersistConfig, walletSlice.reducer);


// Action creators are generated for each case reducer function
export const { increment, clearTransferSent, clearValidity} = walletSlice.actions

export default walletReducer