import { createSlice } from '@reduxjs/toolkit'
import { getAllProducts, getCategories, getProductById, getProductsByCategory, getSimilarProducts, searchProductByCategory } from './productActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  loading: false,
  categories: [],
  products: [],
  product: {},
  similarproducts: [],
  count: 0
}

const productPersistConfig = {
  key: 'product',
  storage,
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
  },
  extraReducers: {
    // get all categories -------------------- ---------------------------------------------------------------
    [getCategories.pending]: (state) => {
      state.loading = true
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.categories = payload.data
    },
    [getCategories.rejected]: (state, { payload }) => {
      state.loading = false
    },

    // -------- Get all products -----------------
    [getAllProducts.pending]: (state) =>{
      state.loading = true
    },
    [getAllProducts.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.products = payload.data
      state.count = payload.total
    },
    [getAllProducts.rejected]: (state) =>{
        state.loading = false
    },

    // product by id
    [getProductById.pending]: (state) =>{

    },
    [getProductById.fulfilled]: (state, {payload}) =>{
      state.product = payload.data
    },
    [getProductById.rejected]: (state) =>{

    },

    // searchg product by category
    [searchProductByCategory.pending]: (state) =>{
      state.loading = true
    },
    [searchProductByCategory.fulfilled]: (state,{payload}) =>{
      state.loading = false
      state.products = payload.data
      state.count = payload.total
    },
    [searchProductByCategory.rejected]: (state) =>{
      state.loading = false
    },

    // ---- Get products by category ------
    [getProductsByCategory.pending]: (state) =>{
      state.loading = true
    },
    [getProductsByCategory.fulfilled]: (state, {payload}) =>{
        state.loading = false
        state.products = payload.data
        state.count = payload.total
    },
    [getProductsByCategory.rejected]: (state) =>{
        state.loading  = false
    },

    [getSimilarProducts.pending]: (state) =>{
      state.loading = true
    },
    [getSimilarProducts.fulfilled]: (state, {payload}) =>{
      state.loading = false
      state.similarproducts = payload.data
    },
    [getSimilarProducts.rejected]: (state) =>{
      state.loading = false
    },


    
  }
})

const productReducer = persistReducer(productPersistConfig, productSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment } = productSlice.actions

export default productReducer