import { createSlice } from '@reduxjs/toolkit'
import {createVirtualAcct, getCrops, getFarmersProfile, getLGAByStateID, getLivestock, getVirtualAcct, requestOTP, userLogin, userRegister, verifyOTPReg, verifyPhoneNumber} from './authActions'
import { getStates } from './authActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  loading: false,
  error: null,
  userInfo: null,
  account_number: null,
  bank_name: null,
  balance: 0,
  token: "",
  isAuthenticated: false,
  states: [],
  lgas: [],
  crops: [],
  livestock: [],
  isOTPSent: false,
  otpVerified: false,
  verifyLoader: false,
  validNumber: false,
  verifycreds: {},
  signUpLoader: false,
  signup_success: false,
  farmerInfo: {},
  isAcctProcessing: false,
  createAcctLoader: false,
  acctCreated: false
}

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['loading']
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    Logout: (state) =>{
      state.isAuthenticated = false
      localStorage.setItem("token", "")
      localStorage.setItem("finagtoken", "");
    },
    clearValidNumber: (state) =>{
      state.validNumber = false
    },
    clearOTpSent: (state) =>{
      state.isOTPSent = false
    },
    clearOtpVerified: (state) =>{
      state.otpVerified = false
    },
    setRegCreds: (state, {payload}) =>{
      if(payload.step === 0){
        state.verifycreds = {
          ...state.verifycreds,
          phone_number: payload.phone_number
        }
      }
      if (payload.step === 1){
        state.verifycreds = {
          ...state.verifycreds,
          first_name: payload.first_name,
          last_name: payload.last_name,
          phone_number: payload.phone_number,
          gender: payload.gender,
          day: payload.day,
          month: payload.month,
          year: payload.year,
        }
      }
      if(payload.step === 2){
        state.verifycreds = {
          ...state.verifycreds,
          state_id: payload.state_id,
          lga_id: payload.lga_id,
          crop_ids: payload.crop_ids,
          livestock_ids: payload.livestock_ids
        }
      }
    },
    clearSignUpSuccess: (state) =>{
      state.signup_success = false
      state.verifycreds = {}
    },
    clearAcctCreated: (state) =>{
      state.acctCreated = false
    }
  },
  extraReducers: {
    // login user -------------------- ---------------------------------------------------------------
    [userLogin.pending]: (state) => {
      state.loading = true
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = {
        ...payload
      }
      state.account_number = payload.account_number
      state.balance = payload.balance
      state.bank_name = payload.bank_name
      state.token = payload.token.access
      state.isAuthenticated = true
      state.isAcctProcessing = payload.bvn_exists
      state.error = null
      localStorage.setItem("token", payload.token.access);
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.isAuthenticated = false
    },

    // get farmers profile
    [getFarmersProfile.pending]: (state) =>{

    },
    [getFarmersProfile.fulfilled]: (state, {payload}) =>{
       state.farmerInfo = payload.data
       state.userInfo.balance = payload.data.wallet_amount * 100
    },
    [getFarmersProfile.rejected]: (state) =>{

    },

    [requestOTP.pending]: (state) =>{
      state.loading = true
    },
    [requestOTP.fulfilled]: (state) =>{
      state.loading = false
      state.isOTPSent = true
    },
    [requestOTP.rejected]: (state) =>{
      state.loading = false
    },

    [verifyOTPReg.pending]: (state) =>{
      state.loading = true
    },
    [verifyOTPReg.fulfilled]: (state) =>{
      state.loading = false
      state.otpVerified = true
    },
    [verifyOTPReg.rejected]: (state) =>{
      state.loading = false
    },

    // verify phone number -------------
    [verifyPhoneNumber.pending]: (state) =>{
      state.verifyLoader = true
    },
    [verifyPhoneNumber.fulfilled]: (state,{payload}) =>{
      state.verifyLoader = false
      state.verifycreds = payload
      state.validNumber = true
    },
    [verifyPhoneNumber.rejected]: (state) =>{
      state.verifyLoader = false
      state.validNumber = false
    },

    // user register -------
    [userRegister.pending]: (state) =>{
        state.signUpLoader = true
    },
    [userRegister.fulfilled] : (state, {payload}) =>{
        state.signUpLoader = false
        state.signup_success = true
    },
    [userRegister.rejected]: (state) =>{
        state.signUpLoader = false
    },

      // ---- states -------------
    [getStates.pending] : (state) =>{
     },
    [getStates.fulfilled] : (state, {payload}) =>{
        state.states = payload.states.sort((a, b) => a.name > b.name ? 1 : -1)
     },
    [getStates.rejected] : (state) =>{
     },

    //  LGAs by State Id
    [getLGAByStateID.pending] : (state) =>{
    },
   [getLGAByStateID.fulfilled] : (state, {payload}) =>{
       state.lgas = payload.lgas.sort((a, b) => a.name > b.name ? 1 : -1)
    },
   [getLGAByStateID.rejected] : (state) =>{
    },

    // get crops
    [getCrops.pending] : (state) =>{
    },
   [getCrops.fulfilled] : (state, {payload}) =>{
       state.crops = payload.data
    },
   [getCrops.rejected] : (state) =>{
    },

    // get livestock
    [getLivestock.pending]: (state) =>{

    },
    [getLivestock.fulfilled]: (state,{payload}) =>{
        state.livestock = payload.data
    },
    [getLivestock.rejected]: (state) =>{

    },

    // get virtual account
    [getVirtualAcct.pending] : (state) =>{

    },
    [getVirtualAcct.fulfilled]:  (state,{payload}) =>{
        let res = payload.data.data
        state.account_number = res.account_number
        state.balance = res.balance * 100
        state.bank_name = res.bank
        state.isAcctProcessing = true
    },
    [getVirtualAcct.rejected]: (state)=>{

    },

    // create virtual account --
      [createVirtualAcct.pending]: (state) =>{
        state.createAcctLoader = true
      },
      [createVirtualAcct.fulfilled]: (state, {payload}) =>{
          if(payload.status === 201){
            state.account_name = payload.account_name
            state.account_number = payload.account_number
            state.createAcctLoader = false
            state.acctCreated = true
          }
          if(payload.status === 200){
            state.isAcctProcessing = true
            state.createAcctLoader = false
            state.acctCreated = true
          }
      },
      [createVirtualAcct.rejected]: (state) =>{
          state.createAcctLoader = false
          state.acctCreated = false
      }


    
  }
})

const authReducer = persistReducer(authPersistConfig, authSlice.reducer);

// Action creators are generated for each case reducer function
export const { increment, Logout, clearValidNumber,clearOTpSent,clearOtpVerified, setRegCreds, clearSignUpSuccess, clearAcctCreated } = authSlice.actions

export default authReducer