import React from 'react'
import buttons from '../../assets/Buttons.svg'

const AddPost = ({ handleClick }) => {
    return (
        <div className='fixed right-[16px] z-[100] bottom-[49px] cursor-pointer' onClick={handleClick}>
            <img src={buttons} alt="buttons" className='' />
        </div>
    )
}

export default AddPost