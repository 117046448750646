import React,{useState} from 'react'
import logo from '../../assets/crop2cash.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { onboardFirstValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'
import Select from 'react-select';
// import back from "../../assets/back.svg";
import { useDispatch, useSelector } from 'react-redux'
import { setRegCreds } from '../../redux/Auth/auth'
import cogoToast from 'cogo-toast'

const OnboardFirstPage = () => {

    const [loading, setLoading] = useState(false)
    const [isGenderError, setIsGenderError] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {verifycreds} = useSelector((state) => state.auth);


    // const backFunc = () => {
    //     navigate("/signup");
    //   };


    const [selectedGender, setSelectedGender] = useState("")
    const [isAdult, setIsAdult] = useState(false);

    const gender = [
      {
        id: 1,
        name: "Male",
        tag: "m"
      },
      {
        id: 2,
        name: "Female",
        tag: "f"
      },
    ]

    const handleGender = (val) =>{
      setIsGenderError(false)
      setSelectedGender(val.tag)
    }

    const validateAge = (val) => {
      const currentDate = new Date();
      const inputDate = new Date(val);
      const diffInMilliseconds = currentDate - inputDate;
      const diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
      console.log(diffInYears)
      setIsAdult(diffInYears >= 18);
    };

    const handleSubmit = (values) => {
        if(!isAdult){
          cogoToast.warn("You must be at least 18 years old")
        }
        else if(selectedGender === ""){
          setIsGenderError(true)
        }
        else{
          setIsGenderError(false)
          setLoading(true)

          const reDate = /([0-9]{4})-([0-9]{2})-([0-9]{2})/;
          const match = reDate.exec(values.dateofbirth);
          let year = match[1]
          let month = match[2]
          let day = match[3]
    
          let resp = {
            first_name: values.firstname,
            last_name: values.lastname,
            phone_number: values.phoneNumber,
            gender: selectedGender,
            day: parseInt(day),
            month: parseInt(month),
            year: parseInt(year),
            step: 1
          }

          dispatch(setRegCreds(resp))
  
          setTimeout(()=>{
              setLoading(false)
              navigate('/onboard/location')
          },1000)
        }
    }


    return ( 
        <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#FBEB96] w-[450px] h-[450px] rounded-full blur-[200px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* signup section divs */}
        <div className="md:max-w-lg lg:max-w-lg mx-auto md:py-16 py-10">

            <div className='flex justify-between items-center'>
                <div>
                    {/* <div 
                       onClick={() => backFunc()}
                        className="bg-[#ECEEEC] w-[24px] h-[24px] rounded-full flex items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer">
                        <img src={back} alt="back" />
                    </div> */}
                </div>
                <div>
                    <p className='text-[#344335] text-sm md:text-base font-semibold'>Sign Up</p>
                </div>
                <div>
                    <p className='text-[#344335] text-sm font-semibold'>1/3</p>
                </div>
            </div>

            {/* progress bar */}
            <div className='flex gap-1 mt-5'>
                <div className='bg-[#2B8C34] rounded-[2px] w-full h-[4px]'></div>
                <div className='bg-[#ABB6AC] rounded-[2px] w-full'></div>
                <div className='bg-[#ABB6AC] rounded-[2px] w-full'></div>
            </div>

          <div className='mt-4'>
            <h6 className="text-[#344335] font-semibold text-xl">
               Basic Information
            </h6>
            <p className="text-[#5C715E] font-medium text-sm mt-1">
            Enter your details below to create your account
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={onboardFirstValidator}
            initialValues={{
              phoneNumber: verifycreds ? verifycreds.phone_number : '',
              firstname: verifycreds ? verifycreds.first_name : '',
              lastname: verifycreds ? verifycreds.last_name : '',
              dateofbirth: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-5" onSubmit={handleSubmit}>

              <div className="">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className={
                      touched.phoneNumber && errors.phoneNumber
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>
                  ) : null}
                </div>


                <div class="grid md:grid-cols-2 md:gap-6 mt-5">
                  <div className=''>
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      First name
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First name"
                      className={
                        touched.firstname && errors.firstname
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstname && errors.firstname ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.firstname && errors.firstname}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className='mt-5 md:mt-0'>
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Last name
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last name"
                      className={
                        touched.lastname && errors.lastname
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastname && errors.lastname ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.lastname && errors.lastname}
                        </small>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='mb-2 mt-5'>
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Date of birth
                    </label>
                    <input
                      type="date"
                      name="dateofbirth"
                      placeholder="Date of birth"
                      className={
                        touched.dateofbirth && errors.dateofbirth
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.dateofbirth}
                      onChange={(e) => {
                        handleChange(e)
                        validateAge(e.currentTarget.value);
                    }}
                      onBlur={handleBlur}
                    />
                    {touched.dateofbirth && errors.dateofbirth ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.dateofbirth && errors.dateofbirth}
                        </small>
                      </div>
                    ) : null}
                  {
                    !errors.dateofbirth && !isAdult  ?
                    (
                      <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                      You must be at least 18 years old
                      </small>
                    </div>
                    )
                    :
                    null
                  }
                  </div>

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Gender
                  </label>
                   <Select 
                        onChange={handleGender}
                        classNamePrefix="select_control"
                        className="select_style"
                        options={gender}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                       />
                </div>
                {isGenderError ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          Gender is required
                      </small>
                    </div>
                  ) : null}

                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                   Continue
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Already have an account? <Link to="/login" className='text-primary font-semibold text-sm'>Log In</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        </>
     );
}
 
export default OnboardFirstPage;