import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const getCategories = createAsyncThunk(
    'categories',
    async (arg, {rejectWithValue, dispatch}) => {
      try {
    
        const res = await axios.get(
          `${apiUrl}product-categories`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )


export const getAllProducts = createAsyncThunk(
    'allProducts',
    async ({page}, {rejectWithValue, dispatch}) => {
      try {
    
        const res = await axios.get(
          `${apiUrl}products?page=${page}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)

export const getProductById = createAsyncThunk(
  'getProductById',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}products/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const searchProductByCategory = createAsyncThunk(
  'searchProductByCategory',
  async ({id,query}, {rejectWithValue, dispatch}) => {
    try {
      let res;
      if (id === "all"){
         res = await axios.get(
          `${apiUrl}products?query=${query}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
      }
      else{
         res = await axios.get(
          `${apiUrl}products?query=${query}&category_id=${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
      }
      
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)


export const getProductsByCategory = createAsyncThunk(
    'productByCategory',
    async ({id, page}, {rejectWithValue, dispatch}) => {
      try {
    
        const res = await axios.get(
          `${apiUrl}products/category/${id}?page=${page}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

  export const getSimilarProducts = createAsyncThunk(
    'similarProducts',
    async ({id}, {rejectWithValue, dispatch}) => {
      try {
    
        const res = await axios.get(
          `${apiUrl}products/category/${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

  




 
