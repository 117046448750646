import React, {useEffect, useState} from 'react'
import back from "../../assets/back.svg";
import NavbarComp from '../../components/Navbar/navbar';
import { useNavigate } from 'react-router-dom';
import input_img from '../../assets/input_img.svg'
import receipt from '../../assets/receipt_long.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../redux/Orders/orderActions';
import PaginationComponent from '../../components/Pagination/Pagination';


const OrdersPage = () => {

    const dispatch = useDispatch()
    const {orders, ordersCount, loader} = useSelector((state) => state.order);

    const navigate = useNavigate()

    const backFunc = () => {
        navigate("/account");
     };



     const [initialTab, setTab] = useState(1);

     const [tabData] = useState([
        { id: 1, name: "tab-1", text: "All" },
        { id: 2, name: "tab-2", text: "Delivered" },
      ]);

      const handleToggle = (id) => {
        setTab(id);
        if(id === 2){
            dispatch(getOrders({
                page: 1
            }))
        }
        else{
            dispatch(getOrders({
                page: 1
            }))
        }
      }

      const capitalizeFirstLetter = (str) => {
        if(str === null){
            return 'PENDING'
        }
        else{
            let val = str.toLowerCase()
            let words = val.split(" ");
           for (let i = 0; i < words.length; i++) {
               words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
           }
            return words.join(" ");
        }
       
      }


  const getOrderBg = (val) => {
    let result
    switch (val) {
      case 'PENDING':
        result = 'processing'
        break;
      case 'DELIVERED':
        result = 'delivered'
        break;
      case 'PROCESSING':
        result = 'processing'
        break;
      case 'DISPATCHED':
       result = 'intransit'
       break;
      case 'CANCELLED' :
        result= 'cancelled'
        break;
      default:
        result = 'processing'
        break
    }
    return result
  }


  const getOrderText = (val) =>{
    let result
    switch (val) {
      case 'PENDING':
        result = 'processingText'
        break;
      case 'DELIVERED':
        result = 'deliveredText'
        break;
      case 'PROCESSING':
        result = 'processingText'
        break;
      case 'DISPATCHED':
       result = 'intransitText'
       break;
      case 'CANCELLED' :
        result= 'cancelledText'
        break;
      default:
        result = 'processingText'
        break
    }
    return result
  }

  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const getMoreItemsByOffset = (page) => {
    setCurrentPage(page)
    if(initialTab === 1){
        dispatch(getOrders({
            page: page
        }))
    }
    else{
        dispatch(getOrders({
            page: page
        }))
    }
  }

  const handleOrder = (id) =>{
    navigate(`/order/${id}`)
  }

  useEffect(()=>{
        dispatch(getOrders({
            page: 1
        }))
    },[dispatch])

    return ( 
        <>
            <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
                <NavbarComp />
                <div className="flex text-center mt-8">
                    <span
                    className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-secondary1 hover:cursor-pointer"
                    onClick={() => backFunc()}
                    >
                    <img src={back} alt="back" />
                    </span>
                    <h6 className="mx-auto font-semibold text-base md:text-base">Orders</h6>
                </div>

                <div className="md:max-w-lg lg:max-w-xl mx-auto md:py-10 py-10">

                    <div className='flex bg-[#ECEEEC] rounded-lg p-2'>
                        {tabData.map((item) => (
                            <div 
                                key={item.id}
                                className={
                                initialTab === item.id ? 
                                "rounded-[7px] bg-[white] shadow-[orderTab] border-[0.5px] border-[#CED5CF] flex justify-center basis-2/4 py-2 cursor-pointer" 
                                : "flex justify-center basis-2/4 py-2 cursor-pointer"
                                }
                                onClick={() => handleToggle(item.id)}
                                >
                                <p 
                                 className = {
                                    initialTab === item.id ? "text-[#344335] font-semibold text-sm" : "text-[#6D6A5E] font-semibold text-sm"
                                 }
                               >{item.text}</p>
                            </div>
                        ))}
                    </div>
                    
                    {/* orders display */}
                    {
                        !loader && orders.length > 0 ?
                        orders.map((item, index) =>(
                        <div key={item.id} className='mt-5 mb-4'>
                            <div className='flex gap-4'>
                                {
                                    item.product && item.product.images && item.product.images.length > 0 ?
                                    <div 
                                    onClick={() => handleOrder(item.id)}
                                    className=''>
                                        <img src={item.product.images[0]} alt="item" className='rounded-md md:w-[140px] w-[130px] h-[130px] md:h-[140px]' />
                                    </div>
                                    :
                                    <div 
                                    onClick={() => handleOrder(item.id)}
                                    className='bg-[#E6E6E6] cursor-pointer rounded-md md:w-[140px] w-[130px] h-[130px] md:h-[140px] flex justify-center items-center'>
                                        <img src={input_img} alt="item" className='rounded-lg w-[40px] h-[40px]' />
                                    </div>
                                }
                              
                                <div>
                                <h6 className='text-[#344335] text-sm font-semibold'>{item.product ? item.product.product_name: ''} </h6>
                                   
                                    <p className='text-[#344335] text-sm mt-1'>
                                    Qty: {item.quantity}
                                  </p>
                                    <p className='text-[#344335] text-sm mt-1'>
                                    ₦{item.product ? item.total_display_price: ''}
                                  </p>
                                    <div className='flex'>
                                        <div className={getOrderBg(item && item.order_status ? item.order_status[item.order_status.length - 1]: 'PENDING')}>
                                            <p className={getOrderText( item.order_status && item.order_status.length > 0 ? item.order_status[item.order_status.length - 1]: 'PENDING')}>
                                            { item.order_status && item.order_status.length > 0 ? capitalizeFirstLetter(item.order_status[item.order_status.length - 1]) : 'PENDING'}
                                            </p>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className='bg-[#CED5CF] w-full h-[0.8px] mt-4'></div>    
                        </div>
                        ))
                        :
                        ""
                    }

             {loader &&
                    ['2', '1', '4', '5', '3'].map((item) => (
                        <div className="mt-5 mb-5 bg-[#FFFFFF] rounded-lg">
                        <div className="animate-pulse w-full">
                            <div className='flex items-center gap-4'>
                                <div className='bg-gray-200 rounded-md md:w-[120px] w-[100px] h-[100px] md:h-[100px]'>
                                </div>
                                <div className='w-full'>
                                   <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                                    <div className="h-4 bg-gray-200 rounded-md w-full mb-2"></div>
                                    <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                                    <div className="h-4 bg-gray-200 rounded-md  w-full"></div>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))}
            
                    {!loader && orders.length === 0 ? (
                        <div className='mt-28'>
                            <div className='flex justify-center items-center'>
                                <div className='bg-[#D9D9D9] w-[80px] h-[80px] flex justify-center items-center rounded-full'>
                                    <img src={receipt} alt="receipt" />
                                </div>
                                </div>
                            <p className='text-[#344335] font-semibold text-sm mt-2 text-center'>No Orders yet</p>
                        <p className='text-[#96A397] text-sm font-semibold mt-1 text-center'>Go to your cart to place your order</p>
                        </div>
                    ) :
                        ""
                    }

                     {/* pagination */}
                    {
                        loader ?
                            ""
                            :
                        orders.length > 0 ?
                            <div className="flex justify-center mt-10 mb-10">
                                <PaginationComponent
                                currentPage={currentPage}
                                totalCount={ordersCount}
                                pageSize={PageSize}
                                onPageChange={(page) => getMoreItemsByOffset(page)}
                            />
                        </div>
                        :
                        ""
                        } 
                      
                    
                </div>
            </div>
        </>
     );
}
 
export default OrdersPage;