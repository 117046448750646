import React,{useEffect} from "react";
import {  useSelector } from "react-redux";
import input_img from '../../assets/input_img.svg'
import {useNavigate} from 'react-router-dom'


function SimilarCard() {
  
  const { loading, similarproducts  } = useSelector((state) => state.product)
  const navigate = useNavigate()

  const handleProduct = (id)=>{
    navigate(`/product/${id}`)
    window.scrollTo(0, 0);
  }


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
       {loading &&
          ['2', '1', '4', '5', '3', '6', '9', '8'].map(() => (
          <div className="animate-pulse w-full">
                <div className="bg-gray-200 md:h-[200px] h-[150px]  rounded-lg  w-full"></div>     
          </div>
        ))}

      {!loading && similarproducts.length
          ? similarproducts.map((item) => (
      <div key={item.id}>
        {
          item.images !== null && item.images.length > 0 
          ?
          <img
          onClick={()=> handleProduct(item.id)}
          className="rounded-md object-cover w-full md:h-[200px] h-[150px] cursor-pointer" src={item.images[0]} alt="product" />
          :
          <div
          onClick={()=> handleProduct(item.id)}
           className='bg-[#E6E6E6] rounded-md w-full md:h-[200px] h-[150px] flex justify-center items-center cursor-pointer'>
            <img 
            src={input_img} alt="item" className='rounded-lg w-[40px] h-[40px]' />
          </div>
        }
      
        <h6 className="text-base text-[#344335] font-bold mt-2">{item.name}</h6>
        <p className="text-[14px] text-[#344335]">{item.organization_name}</p>
        <p className="text-[14px] font-medium text-[#7C8D7E]">{item.product_category ? item.product_category.name: ''}</p>
        <p className="font-semibold text-[14px] text-[#344335]">₦{item.display_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
      </div>
       ))
    :
    ""
    }
  </>
  );
}

export default SimilarCard;
