import React,{useEffect, useState, useMemo, useCallback} from "react";
import search from "../../assets/search.svg";
import banner from "../../assets/complete_solution.jpg";
import banner1 from "../../assets/Banner1.jpg";
import banner2 from "../../assets/Banner2.jpg";
import banner3 from "../../assets/Banner_NHFA_Adjusted Size.png";
import back from "../../assets/back.svg";
import back_right from "../../assets/keyboard_arrow_right.svg";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts, getCategories, getProductsByCategory, searchProductByCategory } from "../../redux/Product/productActions";
import PaginationComponent from '../../components/Pagination/Pagination';
import NavbarComp from "../../components/Navbar/navbar";
import BottomTabComp from "../../components/BottomTab/bottomTab";
import { getCartItems } from "../../redux/Cart/cartActions";
import { debounce } from 'lodash'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './slider.css'
import { getStates } from "../../redux/Auth/authActions";
import warning from '../../assets/warning.svg';
import axios  from 'axios'
import {Mixpanel} from '../../helpers/MixPanel'


const ProductPage = () => {

 
  const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
      const handleOnline = () => {
        setIsOnline(true);
      };

      const handleOffline = () => {
        setIsOnline(false);
      };

      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);

      return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
      };
    }, []);



  const settings = {
    dots: true,
    cssEase: "linear",
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <div
        className="custom-arrow"
      >
        <div className="flex justify-center bg-[#ECEEEC] rounded-full w-[30px] h-[30px] items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer">
        <img src={back} alt="back" />
        </div>
      </div>
    ),
    nextArrow: (
      <div
        className="custom-arrow"
      >
         <div className="flex justify-center bg-[#ECEEEC] rounded-full w-[30px] h-[30px] items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer">
            <img src={back_right} alt="back" />
          </div>
      </div>
    ),
 
  };


  const dispatch = useDispatch()


  const { categories, products, loading, count } = useSelector((state) => state.product)
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth)
  const userId = userInfo && userInfo.id ? userInfo.id: 1


  const [chipName, setChipName] = useState("All");
  const [chipId, setChipID] = useState(null);
  const [searchValue, setSearchValue] = useState('')

    // handle search
    const handleSearch = useMemo(
      () =>
        debounce((val) => {
          //  api to dispatch and make the actions for search
          if(chipName === "All"){
            dispatch(searchProductByCategory({
              id: 'all',
              query: val
            }))
          }
          else{
            dispatch(searchProductByCategory({
              id: chipId,
              query: val
            }))
          }
        }, 750),
      [dispatch, chipId, chipName],
    )
  
    const handleChange = useCallback(
      (e) => {
        let val = e.target.value
        setSearchValue(val)
        handleSearch(val)
      },
      [handleSearch],
    )
  
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handleAll = (name) =>{
    setChipName(name) 
    setChipID(null) 
    dispatch(getAllProducts({
      page: 1
    }))
  }

  const toggleCategory = (category) =>{
    setChipName(category.name)
    setChipID(category.id)
    // fetch product by category id
      dispatch(getProductsByCategory({
        id: category.id,
        page: 1
      }))
  
  }


  const getMoreItemsByOffset = (page) => {
    setCurrentPage(page)
    if(chipName === "All"){
      dispatch(getAllProducts({
        page: page,
      }))
    }
    else{
      dispatch(getProductsByCategory({
        id: chipId,
        page: page
      }))
    }
  }

const API_KEY = 'AIzaSyB4rNuRfdGyqiT_hj1ZTbb4ZUzATjPU9uQ'; // Replace with your API key

const reverseGeocode = useCallback(async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
    );

    if (response.data.results.length > 0) {
      // Extract the formatted address from the response
      const formattedAddress = response.data.results[0].formatted_address;
      Mixpanel.identify(userId)
      Mixpanel.track('Location Track',{
        "Address": formattedAddress,
      })
      // return formattedAddress;
    } else {
      return 'Location not found';
    }
  } catch (error) {
    console.error('Error reverse geocoding:', error);
    return 'Error';
  }
},[userId])

  useEffect(()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Get the user's current location coordinates
          const { latitude, longitude } = position.coords;
          console.log(`User's Location: ${latitude}, ${longitude}`);
  
          // You can use these coordinates to set the map's center or add a marker
          reverseGeocode(latitude, longitude)

        },
        (error) => {
          console.error(`Error getting user's location: ${error.message}`);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  },[reverseGeocode])




// async function reverseGeocode (latitude, longitude) {
//   try {
//     const response = await axios.get(
//       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
//     );

//     if (response.data.results.length > 0) {
//       // Extract the formatted address from the response
//       const formattedAddress = response.data.results[0].formatted_address;

//       Mixpanel.identify(userId)
//       Mixpanel.track('Location Track',{
//         "Address": formattedAddress,
//       })
//       // return formattedAddress;
//     } else {
//       return 'Location not found';
//     }
//   } catch (error) {
//     console.error('Error reverse geocoding:', error);
//     return 'Error';
//   }
// }

  useEffect(()=>{
    if(isAuthenticated){
      dispatch(getCartItems({
        id:userId
      }))
      dispatch(getStates())
    }
    dispatch(getCategories({
      page: 1,
    }))
    dispatch(getAllProducts({
      page: 1,
    }))
  },[dispatch, isAuthenticated, userId])


  return (
    <>
    {!isOnline && <div className="px-4 md:px-10 py-2 bg-red-300 flex gap-2 items-center">
       <img src={warning} className="" alt="warning" />
      <p>Please check that you have an active internet connection!</p>
    </div>
      }
    <div className="px-4 md:px-10 pt-6 pb-20 bg-white min-h-screen">
        <NavbarComp />
   
        <Slider className="my-8" {...settings}>
            {/* first banner */}
            <div>
            <img src={banner2} alt="banner1" className="" />
          </div>
          {/* second banner */}
          <div>
            <img src={banner} alt="banner" className="" />
          </div>
          {/* third banner */}
          <div>
            <img src={banner1} alt="banner2" className="" />
          </div>
            {/* fourth banner */}
            <div>
            <img src={banner3} alt="banner3" className="w-full" />
          </div>
      </Slider>
      <div>
        <div className='mt-14'>
            <div className="relative">
              <input type="text"    
                  onChange={handleChange}   
                  value={searchValue}             
                  className="appearance-none w-full placeholder:text-[#96A397] 
                  placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] 
                  focus:outline-none focus:shadow-input rounded-lg border border-[#CED5CF] bg-white py-3 px-10"
                  placeholder="Search for products" />
                    <img
                    src={search}
                    alt="search"
                    className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer"
                  />
              </div>
        </div>
      </div>
      <div>
        <div className="flex justify-between mt-4">
          <h4 className="font-semibold text-[#344335]">Categories</h4>
          {/* <p className="text-[#2B8C34] text-sm font-medium cursor-pointer">
            View All
          </p> */}
        </div>
        <div className="flex gap-3 overflow-scroll mt-3 scrollbar-hide">
          <p 
           onClick={() => handleAll('All')}
          className={
            chipName === "All" ? 
            "cursor-pointer bg-[#2B8C34] rounded-[4px] text-[14px] py-1 px-2 break-keep flex-none text-white font-medium"
            :
            "cursor-pointer rounded-[4px] text-[14px] border border-[#DDE2DE] py-1 px-2 break-keep flex-none text-secondary2 font-medium"
          }
          >
            All
          </p>
          {categories.map((category, id) => {
            return (
              <p
                key={id}
                onClick={() => toggleCategory(category)}
                className={
                  chipName === category.name ? 
                  "cursor-pointer bg-[#2B8C34] rounded-[4px] text-[14px] py-1 px-2 break-keep flex-none text-white font-medium"
                  :
                  "cursor-pointer rounded-[4px] text-[14px] border border-[#DDE2DE] py-1 px-2 break-keep flex-none text-secondary2 font-medium"
                }
              >
                {category.name}
              </p>
            );
          })}
        </div>
        <div className="my-8 grid grid-cols-2 gap-6 md:gap-8 md:grid-cols-4 lg:auto-cols-auto">
          <ProductCard products={products} />
        </div>

        {
          !loading && products.length === 0
          &&
          <div className="text-center mt-10">
            <p>There are no products available for this category</p>
            </div>
        }

        {/* pagination */}
        {
             loading ?
                ""
                :
              products.length > 0 && products ?
                <div className="flex justify-center mt-10 mb-10">
                     <PaginationComponent
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={PageSize}
                    onPageChange={(page) => getMoreItemsByOffset(page)}
                    />
                </div>
            :
            ""
            } 
      </div>
    </div>

      {/* footer */}
     <BottomTabComp />
  </>
  );
};

export default ProductPage;
