import React,{useState} from 'react'
import logo from '../../assets/newLogo.svg'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { forgotPinValidator } from '../../validationSchema/validator'
import warning from '../../assets/warning.svg'

const ForgotPinPage = () => {

  const [loading, setLoading] = useState(false)

  const handleSubmit = (values) => {
    setLoading(true)

    setTimeout(()=>{
        setLoading(false)
    },3000)
  }

    return (
        <>
 <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* forgot section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">
          <div>
            <h6 className="text-primary font-semibold text-xl">
              Forgotten your pin?
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Don’t feel bad, it happens to the best of us. Enter your phone
              number and we'll send you a default code to reset you pin.
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={forgotPinValidator}
            initialValues={{
                phoneNumber: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form  className="mt-8" onSubmit={handleSubmit}>
                 <div>
                  <label htmlFor='phoneNumber' className="block mb-2 text-secondary text-sm font-medium">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className={
                      touched.phoneNumber && errors.phoneNumber
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Send Reset Code
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Remember pin? <Link to="/login" className='text-primary font-semibold text-sm'>Back to Login</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        </>
      );
}
 
export default ForgotPinPage;