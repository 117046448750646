import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const getCartItems = createAsyncThunk(
    'cartItems',
    async ({id}, {rejectWithValue, dispatch}) => {
      try {
    
        const res = await axios.get(
          `${apiUrl}farmers/cart/${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)

export const addItemsToCart = createAsyncThunk(
    'addItemsToCart',
    async ({ result }, { rejectWithValue, dispatch, getState }) => {
      let cart = []
      let cartItem;
      const userId  = getState().auth.userInfo.id
      let type = result.productType ? result.productType.toUpperCase(): ""
      
      if(type === "CROP" || type === "crop"){
        cartItem = [
          {
            ...cart,
            crop_input_id: result.productId,
            quantity: result.quantity,
            item_type: type,
            total_item_price: result.productPrice,
            vendor_id: result.vendorId,
            vendor_name: result.vendorName
          }
        ]
      }
      if(type === "ADVISORY" || type === "advisory"){
        cartItem = [
          {
            ...cart,
            advisory_product_id: result.productId,
            quantity: result.quantity,
            item_type: type,
            total_item_price: result.productPrice,
            vendor_id: result.vendorId,
            vendor_name: result.vendorName
          }
        ]
      }
      if(type === "LIVESTOCK" || type === "livestock"){
        cartItem = [
          {
            ...cart,
            livestock_product_id: result.productId,
            quantity: result.quantity,
            item_type: type,
            total_item_price: result.productPrice,
            vendor_id: result.vendorId,
            vendor_name: result.vendorName
          }
        ]
      }
      if(type === "MECHANIZATION" || type === "mechanization"){
        cartItem = [
          {
            ...cart,
            mechanization_product_id: result.productId,
            quantity: result.quantity,
            item_type: type,
            total_item_price: result.productPrice,
            vendor_id: result.vendorId,
            vendor_name: result.vendorName
          }
        ]
      }
      if(type === "INSURANCE" || type === "insurance"){
        cartItem = [
          {
            ...cart,
            insurance_product_id: result.productId,
            quantity: result.quantity,
            item_type: type,
            total_item_price: result.productPrice,
            vendor_id: result.vendorId,
            vendor_name: result.vendorName
          }
        ]
      }
  
      if(type === ""){
        cartItem = []
      }
  
      try {
        const res = await axios.post(
          `${apiUrl}cart/save`,
          { 
            farmer_id: userId,
            total_price: result.productPrice,
            cart_items: cartItem,
            checkout : false,
          },
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 201){
            cogoToast.success("Product has been added to cart. Check the cart to complete the order!")
            window.heap.identify(`${userId}`)
            window.heap.track('Add To Cart',
            {
              "Total Price": result.productPrice,
              "Cart Items":  cartItem
            })
            return res.data
        }
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

export const UpdateCartQantity = createAsyncThunk(
    'updateItemsInCart',
    async ({ id, quantity }, { rejectWithValue, dispatch }) => {
      try {
        const res = await axios.put(
          `${apiUrl}cart_items/${id}`,
          { 
            quantity: quantity,
          },
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return {data:res.data, id, quantity}
        }
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)
  

export const deleteFarmersCart = createAsyncThunk(
    'deleteFarmersCart',
    async ({ id }, { rejectWithValue, dispatch }) => {
    
  
      try {
        const res = await axios.delete(
          `${apiUrl}cart_items/${id}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            cogoToast.success("Product has been deleted from cart!")
            return {data:res.message, id}
        }
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
)
  
export const createOrder = createAsyncThunk(
  'createOrder',
  async ({ resp }, { rejectWithValue, getState, dispatch }) => {
      const cartItems  = getState().cart.cart
      const userId  = getState().auth.userInfo.id

    try {
      const res = await axios.post(
        `${apiUrl}cart/checkout`,
        { 
          total_price: resp.totalPrice,
          farmer_id: userId,
          state_id: resp.stateID,
          lga_id: resp.lgaID,
          address: resp.deliveryAddress,
          cart_items: cartItems,
          pin: resp.pin
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          cogoToast.success("Order created successfully")
          return res.data
          
      }
    } catch (error) {
      if(error.response.status === 400){
        cogoToast.error("Oops, looks like there is an issue processing your order!")
        return rejectWithValue(error.response)
      }
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)




 
