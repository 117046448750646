import React, { useState, useEffect } from 'react'
import eye_off from '../../assets/eye-off.svg'
import eye from '../../assets/eye.svg'
import logo from '../../assets/newLogo.svg'
import warning from '../../assets/warning.svg'
import lock_icon from '../../assets/lock_reset.svg'
import { Form, Formik } from 'formik'
import { loginValidator } from '../../validationSchema/validator'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../redux/Auth/authActions'
import Modal from '../../components/Modals/modal'


const LoginPage = () => {

  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  const showModal = () => {
    setShow(!show)
  }

  const [passwordShown, setPasswordShown] = useState(false)

  const { isAuthenticated, loading } = useSelector((state) => state.auth);


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const dispatch = useDispatch()

  const handleForgot = () => {
    
    setShow(true)
  }


  const handleSubmit = (values) => {
    dispatch(userLogin(values))
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  }, [isAuthenticated, navigate])



  return (
    <>

      {/* otp confirmation modal */}
      <Modal show={show} showModal={showModal}
        title="Pin Reset"
        subtitle="To reset your pin please call customer care"
      >

        <div className='mt-10'>

          <div className='flex justify-center mt-4'>
            <img src={lock_icon} alt="lock" />
          </div>

          <p className='text-[#7C8D7E] font-medium text-sm text-center mt-4'>
            To reset your pin please call customer care.
          </p>


          <div className='mt-10 flex justify-center items-center'>
            <a
              href='tel:09030009891'
              className='bg-primary 
                        disabled:bg-[#ABB6AC] 
                        text-center
                        rounded-lg w-full text-white py-4 px-6 text-sm'>Contact +2349030009891</a>
          </div>
        </div>
      </Modal>

      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[120px] md:h-[40px]" />
          </Link>
          <div className="z-10">

          </div>
        </div>

        {/* login section divs */}
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">

          <div>
            <h6 className="text-primary font-semibold text-xl">Welcome Back</h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Enter your Credentials below to get Started{' '}
            </p>

          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            validationSchema={loginValidator}
            initialValues={{
              phoneNumber: '',
              pin: '',
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-8" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor='phoneNumber' className="block mb-2 text-secondary text-sm font-medium">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Enter your phone number"
                    className={
                      touched.phoneNumber && errors.phoneNumber
                        ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                        : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    }
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.phoneNumber && errors.phoneNumber}
                      </small>
                    </div>
                  ) : null}
                </div>

                <div className="mt-5">
                  <label htmlFor='pin' className="block mb-2 text-secondary text-sm font-medium">
                    Pin
                  </label>
                  <div className="relative">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="pin"
                      placeholder="Enter your 4 digit pin"
                      className={
                        touched.pin && errors.pin
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      value={values.pin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.pin && errors.pin ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                        {touched.pin && errors.pin}
                      </small>
                    </div>
                  ) : null}
                </div>
                <div className="mt-2">
                  <small
                    onClick={handleForgot}
                    className="text-secondary font-medium text-sm cursor-pointer"
                  >
                    Forgotten your pin?
                  </small>
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary 
                    disabled:bg-[#ABB6AC] 
                    rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                    Log In
                  </button>
                </div>

                <div className='text-center mt-3'>
                  <p className='text-[#344335] text-sm font-semibold'>Don’t have an account? <Link to="/signup" className='text-primary font-semibold text-sm'>Sign Up</Link> </p>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </>
  );
}

export default LoginPage;