import { createSlice } from '@reduxjs/toolkit'
import { addItemsToCart, createOrder, deleteFarmersCart, getCartItems, UpdateCartQantity } from './cartActions'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
} from 'redux-persist'

const initialState = {
  loading: false,
  cart: [],
  deleteLoader: false,
  addCartLoader: false,
  processloader: [],
  createLoader : false,
  isOrderPlaced: false
}

const cartPersistConfig = {
  key: 'cart',
  storage,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    ProcessLoad: (state, {payload}) =>{
      state.processloader = [...state.processloader, payload]
    },
    clearOrderPlaced: (state) =>{
      state.isOrderPlaced = false
    },
  },
  extraReducers: {
    // get all cart items -------------------- ---------------------------------------------------------------
    [getCartItems.pending]: (state) => {
      state.loading = true
    },
    [getCartItems.fulfilled]: (state, { payload }) => {
      state.loading = false
      let res = payload.data
      if(res && res.cart_items){
        if(res.cart_items.some((val)=> val.id === null)){
          state.cart = []
        }
        else{
          state.cart = res.cart_items
        }
      }
      else{
        state.cart = []
      }          
    },
    [getCartItems.rejected]: (state, { payload }) => {
      state.loading = false
    },

    // add cart items------
    [addItemsToCart.pending]: (state) =>{
      state.addCartLoader = true
    },
    [addItemsToCart.fulfilled]: (state, {payload}) =>{
      state.cart = payload.data.cart_items
      state.addCartLoader = false
    },
    [addItemsToCart.rejected]: (state) =>{
      state.addCartLoader = false
    },

    // delete farmers cart items------
    [deleteFarmersCart.pending]: (state)=>{
      state.deleteLoader = true
    },
    [deleteFarmersCart.fulfilled]: (state, {payload})=>{
      state.deleteLoader = false
      let removedItem = state.cart.filter(item => item.id !== payload.id)
      state.cart = removedItem
    },
    [deleteFarmersCart.rejected]: (state)=>{
      state.deleteLoader = false
    },

    // Update quantity of items in cart ------
    [UpdateCartQantity.pending]: (state, action) =>{
    },
    [UpdateCartQantity.fulfilled]: (state,{payload}) =>{
      let resp = state.processloader.splice(1)
      state.processloader = resp
      let incrementItems = state.cart.map((item) => item.id === payload.id ?
      { 
        ...item,
         quantity: payload.quantity
       } : item);
     state.cart = incrementItems
    },
    [UpdateCartQantity.rejected]: (state) =>{

    },

    // create order
    [createOrder.pending] : (state) =>{
      state.createLoader = true
    },
    [createOrder.fulfilled]: (state) =>{
      state.createLoader = false
      state.isOrderPlaced = true
      state.cart = []
    },
    [createOrder.rejected]: (state) =>{
      state.createLoader = false
    }
    
  }
})

const cartReducer = persistReducer(cartPersistConfig, cartSlice.reducer);


// Action creators are generated for each case reducer function
export const { increment,ProcessLoad,clearOrderPlaced} = cartSlice.actions

export default cartReducer