import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Auth/login";
import SignUpPage from "./pages/Auth/signup";
import OnboardFirstPage from "./pages/Auth/OnboardFirst";
import OnboardSecondPage from "./pages/Auth/OnboardSecond";
import OnboardThirdPage from "./pages/Auth/OnboardThird";
// import HomePage from './pages/Home/home'
import ForgotPinPage from "./pages/Auth/forgot";
import CartPage from "./pages/Cart/cart";
import ProductPage from "./pages/Product/product";
import ProductDetails from "./pages/Product/productDetails";
import Account from "./pages/Account/account";
import Wallet from "./pages/Wallet/wallet";
import SendMoneyPage from "./pages/Wallet/sendMoney";
import ProfilePage from "./pages/Profile/profile";
import OrdersPage from "./pages/Orders/orders";
import OrderDetailsPage from "./pages/Orders/orderDetails";
import TrackOrderPage from "./pages/Orders/trackOrder";
import NoInternetPage from "./pages/Internet/internet";
import TrackerPage from "./pages/Tracker/Tracker";
import ProtectedRoute from "./components/PrivateRoutes/userRoute";
import ResetPinPage from "./pages/Auth/reset";
import VerifyOTPPage from "./pages/Auth/verifyOtp";

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" exact element={<ProductPage />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpin" element={<ForgotPinPage />} />
          <Route path="/resetpin" element={<ResetPinPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/verify-otp" element={<VerifyOTPPage />} />
          <Route path="/onboard/basic-info" element={<OnboardFirstPage />} />
          <Route path="/onboard/location" element={<OnboardSecondPage />} />
          <Route path="/onboard/create-pin" element={<OnboardThirdPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/account" element={
               <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
                } />
          <Route path="/wallet" element={
                <ProtectedRoute>
                  <Wallet />
                </ProtectedRoute>
              } 
                />
          <Route path="/orders" element={
                  <ProtectedRoute>
                     <OrdersPage />
                    </ProtectedRoute>
                    } />
          <Route path="/order/:id" element={
                   <ProtectedRoute>
                     <OrderDetailsPage />
                    </ProtectedRoute>
                    } />
          <Route path="/trackorder/:id" element={
                <ProtectedRoute>
                  <TrackOrderPage />
                  </ProtectedRoute>
                  } />
          <Route path="/send-money" element={
                <ProtectedRoute>
                  <SendMoneyPage />
                  </ProtectedRoute>
                  } />
          <Route path="/profile" element={
                <ProtectedRoute>
                    <ProfilePage />
                </ProtectedRoute>
                } />
          <Route path="/tracker" element={<TrackerPage />} />
          <Route path="/no-internet" element={<NoInternetPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
