import React,{useEffect, useState} from 'react'
import logo from '../../assets/crop2cash.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Select from 'react-select';
import back from "../../assets/back.svg";
import { getCrops, getLGAByStateID, getLivestock, getStates } from '../../redux/Auth/authActions';
import { useSelector, useDispatch } from 'react-redux';
import warning from '../../assets/warning.svg'
import { setRegCreds } from '../../redux/Auth/auth';


const OnboardSecondPage = () => {


    const [loading, setLoading] = useState(false)
    const [isStateError, setIsStateError] = useState(false)
    const [isLGAError, setLGAError] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {states, lgas, crops, livestock} = useSelector((state) => state.auth);


    const backFunc = () => {
      navigate("/signup");
    };


    const [selectedStates, setSelectedStates] = useState("")
    const [selectedCrops, setSelectedCrops] = useState([])
    const [selectedLivestock, setSelectedLivestock] = useState([])
    const [selectedLGA, setSelectedLGA] = useState("")


    const handleStates = (val) =>{
      setIsStateError(false)
      setSelectedStates(val.id)

      dispatch(getLGAByStateID({
        id: val.id
      }))
    }

    const handleCrops = (val) =>{
      let cropArray = [];
        val.map(o => 
          cropArray.push(o.id)
      );
      setSelectedCrops(cropArray) 
    }

    const handleLivestock = (val) =>{
      let livestockArray = [];
        val.map(o => 
          livestockArray.push(o.id)
      );
      setSelectedLivestock(livestockArray) 
    }

    const handleLGA = (val) =>{
      setSelectedLGA(val.id)
      setLGAError(false)
    }


    const handleSubmit = (values) => {
        if(selectedStates === ""){
          setIsStateError(true)
        }
        else if(selectedLGA === ""){
          setLGAError(true)
        }
        else{
          setLoading(true)
          let resp = {
            state_id: selectedStates,
            lga_id: selectedLGA,
            crop_ids: selectedCrops,
            livestock_ids: selectedLivestock,
            step: 2
          }
          dispatch(setRegCreds(resp))
  
          setTimeout(()=>{
              setLoading(false)
              navigate('/onboard/create-pin')
          },1000)
        }   
    }

    useEffect(()=>{
      dispatch(getStates())
      dispatch(getCrops())
      dispatch(getLivestock())
    },[dispatch])


    return ( 
        <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#FBEB96] w-[450px] h-[450px] rounded-full blur-[200px]"></div>

        <div className="flex justify-between">
          <Link to="/">
            <img src={logo} alt="logo" className="md:w-[200px] w-[150px] md:h-[40px]" />
          </Link>
          <div className="z-10">
            
          </div>
        </div>

        {/* signup section divs */}
        <div className="md:max-w-lg lg:max-w-lg mx-auto md:py-16 py-10">

        <div className='flex justify-between items-center'>
                <div>
                    <div 
                       onClick={() => backFunc()}
                        className="bg-[#ECEEEC] w-[24px] h-[24px] rounded-full flex items-center py-1 px-2 border border-[#5C715E] hover:cursor-pointer">
                        <img src={back} alt="back" />
                    </div>
                </div>
                <div>
                    <p className='text-[#344335] text-sm md:text-base font-semibold'>Sign Up</p>
                </div>
                <div>
                    <p className='text-[#344335] text-sm font-semibold'>2/3</p>
                </div>
            </div>

           {/* progress bar */}
           <div className='flex gap-1 mt-5'>
                <div className='bg-[#2B8C34] rounded-[2px] w-full h-[4px]'></div>
                <div className='bg-[#2B8C34] rounded-[2px] w-full'></div>
                <div className='bg-[#ABB6AC] rounded-[2px] w-full'></div>
            </div>

          <div className='mt-4'>
            <h6 className="text-[#344335] font-semibold text-xl">
               Location
            </h6>
            <p className="text-[#5C715E] font-medium text-sm mt-1">
            Enter your details below to create your account
            </p>
          </div>
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
            initialValues={{
             
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form className="mt-5" onSubmit={handleSubmit}>

                <div className="">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    State
                  </label>
                   <Select 
                        onChange={handleStates}
                        classNamePrefix="select_control"
                        className="select_style"
                        options={states}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                       />
                </div>
                {isStateError ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          State is required
                      </small>
                    </div>
                  ) : null}

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                  Local Government Area
                  </label>
                   <Select 
                        onChange={handleLGA}
                        classNamePrefix="select_control"
                        className="select_style"
                        options={lgas}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                       />
                </div>
                {isLGAError ? (
                    <div className="flex">
                      <img src={warning} className="" alt="warning" />
                      <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          LGA is required
                      </small>
                    </div>
                  ) : null}


                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Crop
                  </label>
                   <Select 
                        isMulti
                        onChange={handleCrops}
                        classNamePrefix="select_control"
                        className="select_style"
                        options={crops}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                       />
                </div>

                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Livestock
                  </label>
                   <Select 
                        isMulti
                        onChange={handleLivestock}
                        classNamePrefix="select_control"
                        className="select_style"
                        options={livestock}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                   
                       />
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                  >
                   Continue
                  </button>
                </div>

                <div className='text-center mt-3'>
                    <p className='text-[#344335] text-sm font-semibold'>Already have an account? <Link to="/login" className='text-primary font-semibold text-sm'>Log In</Link> </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
        </>
     );
}
 
export default OnboardSecondPage;