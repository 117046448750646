import React from 'react';
import { CSSTransition } from 'react-transition-group'
import './drawer.css'
import icon from '../../assets/person_filled (1).svg'
import { useNavigate } from 'react-router-dom';

const DrawerComponent = ({ show, showModal }) => {
    const navigate = useNavigate()
    return (
        <>
            <CSSTransition
                in={show}
                unmountOnExit
                timeout={{ enter: 0, exit: 300 }}
            >
                <div className="modal 
                    overflow-y-auto overflow-x-hidden bg-[#344335]
                    bg-opacity-50 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 
                    opacity-0 transition-all duration-[0.3s] h-screen" >
                    <div class="relative w-full h-full md:h-auto modal-content translate-y-[-200px] transition-all duration-[0.3s]">
                        {/* modal content */}
                        <div class="w-full absolute bottom-0 bg-white rounded-lg py-4 px-5 drawer">
                            <div className='mt-[25px] flex flex-col justify-center items-center'>
                                <div className='w-[67px] h-[2px] bg-[#B8B3A3] mb-[17px]' onClick={showModal}></div>
                                <img src={icon} alt="perosn filled" className='mb-4 h-[88px]' />
                            </div>

                            <div className='px-4'>
                                <p className='text-sm font-normal leading-[18px] text-[#344335]'>Please sign up or login to your account to perform more actions</p>
                                <div className='mt-[27px] flex gap-[38px]'>
                                    <button
                                        type="submit"
                                        onClick={() => navigate(`/login`)}
                                        className="border-primary border-[1px] text-primary bg-white
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-3 font-medium transition-all"
                                    >
                                        Log In
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={() => navigate(`/signup`)}
                                        className="bg-primary
                    disabled:bg-[#ABB6AC] 
                     rounded-lg w-full py-3 text-white font-medium hover:bg-[#24752B] transition-all"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </CSSTransition>
        </>
    );
}

export default DrawerComponent;