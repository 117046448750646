import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast"


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const userLogin = createAsyncThunk(
    'login',
    async ({ phoneNumber, pin }, { rejectWithValue }) => {
      try {
        const res = await axios.post(
          `${apiUrl}auth/signin`,
          { 
            phone_number: phoneNumber, 
            pin
          }
        )
        if(res.status === 200){
            let result = res.data
            window.heap.identify(`${result.id}`)
            window.heap.track('Login')
            window.heap.addUserProperties({
               FirstName: `${result.first_name}`, 
               LastName: `${result.last_name}`, 
              });
          return res.data
        }
      } catch (error) {
        if(error.response.status === 401){
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
        else{
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
      }
    }
 )

 export const requestOTP = createAsyncThunk(
  'requestOTP',
  async ({ phone_number }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/request-otp`,
        { 
          phone_number: phone_number, 
        }
      )
      if(res.status === 200){
          cogoToast.success('OTP sent successfully!')
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Oops looks like the number entered is not valid!')
        return rejectWithValue(error.response.data)
       }
       else {
        cogoToast.error('Oops looks like the number entered is not valid!')
        return rejectWithValue(error.response.data)
      }
    }
  }
)

 export const verifyPhoneNumber = createAsyncThunk(
  'verifyPhone',
  async ({ phone_number }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/verify`,
        { 
          phone_number: phone_number, 
        }
      )
      if(res.status === 200){
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('This account already exists!')
        return rejectWithValue(error.response.data)
       }
       else {
          cogoToast.error('Kindly check that the credentials entered is valid!')
        return rejectWithValue(error.response.data)
      }
    }
  }
)

export const verifyOTPReg = createAsyncThunk(
  'verifyOTPReg',
  async ({ phone_number, otp }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${apiUrl}auth/verify-otp?phone_number=${phone_number}&otp=${otp}`,
      )
      if(res.status === 200){
        cogoToast.success('OTP verified successfully!')
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Oops, looks like the code entered is not valid or has been used!')
        return rejectWithValue(error.response.data)
       }
       else {
        cogoToast.error('Oops, looks like the code entered is not valid or has been used!')
        return rejectWithValue(error.response.data)
      }
    }
  }
)

export const userRegister = createAsyncThunk(
  'register',
  async ({ phone_number, first_name, last_name, gender, pin,
     day, month, year, state_id, lga_id, crop_ids, livestock_ids }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/signup`,
        { 
          phone_number: phone_number,
          first_name,
          last_name,
          gender,
          pin,
          day,
          month,
          year,
          state_id,
          lga_id,
          crop_ids,
          livestock_ids
        }
      )
      if(res.status === 200){
          cogoToast.success("Registration Successful!, Kindly login to proceed");
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
          cogoToast.error('Oops! Looks like a user already exist with this credentials.')
        return rejectWithValue(error.response.data.error)
      } else {
          cogoToast.error('Oops! Looks like a user already exist with this credentials.')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const getStates = createAsyncThunk(
  'states',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}states`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getLGAByStateID = createAsyncThunk(
  'lgas',
  async ({id},{rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}lgas/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getCrops = createAsyncThunk(
  'crops',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}crops`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getLivestock = createAsyncThunk(
  'livestock',
  async (arg, {rejectWithValue, dispatch}) => {
    try {
  
      const res = await axios.get(
        `${apiUrl}livestock`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)
 
export const getFarmersProfile = createAsyncThunk(
  'farmersProfile',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    try {
      const userId  = getState().auth.userInfo.id
      const res = await axios.get(
        `${apiUrl}profile/${userId}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
      }
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const createVirtualAcct = createAsyncThunk(
  'createVirtualAcct',
  async ({ bvn, pin }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}virtual-account`,
        { 
          bvn: bvn,
          pin: pin
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          cogoToast.success("Your request has been received and is been processed!")
          return {
            data: res.data,
            status: res.status
          }
      }
      if(res.status === 201){
        cogoToast.success("Account details created successfully")
        return {
          data: res.data,
          status: res.status
        }
      }
    } catch (error) {
      if(error.response.status === 400){
        cogoToast.error("Please check that the credentials entered is valid!")
        return rejectWithValue(error.response)
      }
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        cogoToast.error("Oops!, Please try again later, looks like the server is unavailable!")
        return rejectWithValue(error.response)
      }
    }
  }
)

export const getVirtualAcct = createAsyncThunk(
  'getVirtualAcct',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}virtual-account`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data
          
      }
    } catch (error) {
      if(error.response.status === 400){
        rejectWithValue(error.response)
      }
      if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)