import React from 'react'
import back from "../../assets/back.svg";
import NavbarComp from '../../components/Navbar/navbar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import input_img from '../../assets/input_img.svg'
import { useSelector } from 'react-redux';
import moment from "moment";


const OrderDetailsPage = () => {

    const navigate = useNavigate()
    const {orders} = useSelector((state) => state.order);

    const query = useParams();
    let id = query.id

    let order = orders.find((val) => val.id === id)



    const backFunc = () => {
        navigate("/orders");
     };

     const capitalizeFirstLetter = (str) => {
        if(str === null){
            return 'PENDING'
        }
        else{
            let val = str.toLowerCase()
            let words = val.split(" ");
           for (let i = 0; i < words.length; i++) {
               words[i] = words[i][0].toUpperCase() +  words[i].substr(1);
           }
            return words.join(" ");
        }
       
      }


  const getOrderBg = (val) => {
    let result
    switch (val) {
      case 'PENDING':
        result = 'processing'
        break;
      case 'DELIVERED':
        result = 'delivered'
        break;
      case 'PROCESSING':
        result = 'processing'
        break;
      case 'DISPATCHED':
       result = 'intransit'
       break;
      case 'CANCELLED' :
        result= 'cancelled'
        break;
      default:
        result = 'processing'
        break
    }
    return result
  }


  const getOrderText = (val) =>{
    let result
    switch (val) {
      case 'PENDING':
        result = 'processingText'
        break;
      case 'DELIVERED':
        result = 'deliveredText'
        break;
      case 'PROCESSING':
        result = 'processingText'
        break;
      case 'DISPATCHED':
       result = 'intransitText'
       break;
      case 'CANCELLED' :
        result= 'cancelledText'
        break;
      default:
        result = 'processingText'
        break
    }
    return result
  }

    return ( 
        <>
             <div className="px-4 md:px-10 pt-6 pb-20 min-h-screen">
                <NavbarComp />
                <div className="flex text-center mt-8">
                    <span
                    className="bg-[#ECEEEC] rounded-full flex items-center py-1 px-2 border border-secondary1 hover:cursor-pointer"
                    onClick={() => backFunc()}
                    >
                    <img src={back} alt="back" />
                    </span>
                    <h6 className="mx-auto font-semibold text-base md:text-base">Order Details</h6>
                </div>

                <div className="md:max-w-lg lg:max-w-xl mx-auto md:py-10 py-10">

                    <div className='bg-[#ECEEEC] rounded-lg py-6 px-4'>
                        <div className='flex items-center justify-between'>
                            <p className='text-[#344335] text-sm font-semibold'>Order ID</p>
                            <p className='text-[#344335] text-sm font-semibold'>{order && order.id ? order.id : ''}</p>
                        </div>
                        <div className='flex justify-between items-center mt-3'>
                            <p className='text-[#344335] text-sm font-semibold'>1 Item</p>
                             <p className='text-[#7C8D7E] font-medium text-sm'>Subtotal: <span className='text-[#344335] text-sm font-semibold'>₦{order && order.total_display_price ? order.total_display_price : ''} </span></p>
                        </div>
                        <div className='mt-4 text-center'>
                            <p className='text-[#344335] font-semibold text-xs'>Placed On {order && order.created ? moment(order.created).format('Do, MMM YYYY') : ''}</p>
                        </div>
                    </div>

                    <div className='flex gap-4 mt-6'>
                        {
                             order && order.product && order.product.images !== null ?
                              <div 
                              className=''>
                                  <img src={order.product.images[0]} alt="item" className='rounded-md md:w-[140px] w-[130px] h-[130px] md:h-[140px]' />
                              </div>
                              :
                              <div 
                              className='bg-[#E6E6E6] cursor-pointer rounded-md md:w-[140px] w-[130px] h-[130px] md:h-[140px] flex justify-center items-center'>
                                  <img src={input_img} alt="item" className='rounded-lg w-[40px] h-[40px]' />
                              </div>
                           }
                            
                        <div>
                        <h6 className='text-[#344335] text-sm font-semibold'>{order && order.product ? order.product.product_name: ''} </h6>
                           
                            <p className='text-[#344335] text-sm mt-1'>
                            Qty: {order && order.product ? order.quantity: ''}
                            </p>
                            <p className='text-[#344335] text-sm mt-1'>
                            ₦{order && order.product ? order.total_display_price : ''}
                            </p>
                            <div className='flex'>
                                <div className={getOrderBg(order && order.order_status ? order.order_status[order.order_status.length - 1]: 'PENDING')}>
                                <p className={getOrderText( order && order.order_status.length > 0 ? order.order_status[order.order_status.length - 1]: 'PENDING')}>
                                { order && order.order_status.length > 0 ? capitalizeFirstLetter(order.order_status[order.order_status.length - 1]) : 'PENDING'}
                                    </p>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                    <div className='mt-8 flex gap-3'>
                            <Link to="/"
                                className="bg-primary 
                                disabled:bg-[#ABB6AC] 
                                rounded-lg w-full py-3 px-6
                                flex justify-center
                                 text-white font-medium hover:bg-[#24752B] transition-all"
                            >
                                Buy Again
                            </Link>
                    
                            <Link
                                to={`/trackorder/${id}`}
                                type="submit"
                                className="bg-white
                                disabled:bg-[#ABB6AC] 
                                border-[#2B8C34]
                                border flex justify-center
                                rounded-lg w-full py-3 px-6 text-[#2B8C34] font-medium hover:bg-[#24752B]
                                hover:text-white transition-all"
                            >
                                Track Order
                            </Link> 
                    </div>

                    <div className='mt-8'>
                        <p className='text-[#344335] text-sm font-semibold'>Delivery Address</p>

                        <div className='mt-3 bg-[#ECEEEC] rounded-lg py-4 px-4'>
                            <p className='text-[#344335] text-sm font-semibold'>{order && order.farmer ? order.farmer.last_name: ''} {order && order.farmer ? order.farmer.first_name: ''}</p>
                            <p className='text-[#344335] text-sm font-medium mt-1'>
                            {order && order.sales_delivery_location ? order.sales_delivery_location : ''}
                            </p>
                            <p className='text-[#344335] text-sm font-medium mt-1'>{order && order.farmer ? order.farmer.phone_number: ''} </p>
                        </div>
                    </div>

                    <div className='mt-10 text-center'>
                        <p className='text-[#000000] text-sm font-medium'>Having issues with your order?</p>
                        <a href="tel:09030009891" className='text-[#2B8C34] text-sm font-medium'>Contact Customer Support</a>
                    </div>

                 </div>


            </div>
        </>
     );
}
 
export default OrderDetailsPage;