import * as Yup from 'yup'

export const loginValidator = Yup.object({
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
})

export const forgotPinValidator = Yup.object({
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
})

export const resetPinValidator = Yup.object({
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  newpin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
})

export const registerValidator = Yup.object({
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number")
})


export const onboardFirstValidator = Yup.object({
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  dateofbirth: Yup.string().required("Date of birth is required")
})

export const onboardThirdValidator = Yup.object({
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
})

export const locationValidator = Yup.object({
  stateID: Yup.string().required("Choose a state"),
  lgaID: Yup.string().required("Choose LGA"),
   deliveryAddress: Yup.string().required('Delivery address is required')
}); 


export const sendMoneyValidator = Yup.object({
  accountNumber:  Yup.string()
  .min(10, 'Account number cannot be less than 10 digits')
  .max(10, 'Exceeded characters for Account Number')
  .required("Account Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid Account Number"),
  amount: Yup.string()
    .required("Enter an amount")
    .matches(/^[0-9]*\.?[0-9]{0,2}$/, "Enter a valid amount"),
  narration: Yup.string()
}); 


export const profileValidator = Yup.object({
  firstName:  Yup.string().required('First Name is required'),
  lastName:  Yup.string().required('Last Name is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  dateOfBirth: Yup.string().required('Date Of Birth is required'),
  gender: Yup.string(),
  bvn: Yup.string().required('BVN is required'),
}); 

export const accountValidator = Yup.object({
  bvn:  Yup.string()
  .min(11, 'BVN number cannot be less than 11 digits')
  .max(11, 'Exceeded characters for BVN Number')
  .required("BVN Number is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid BVN Number"),
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
}); 



